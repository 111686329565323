/**
 * @generated SignedSource<<bdc383b34af08ad7be599155cdd1757d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MenuOptionsSidebar_Query$variables = {
  userId: number;
};
export type MenuOptionsSidebar_Query$data = {
  readonly companyEnabledFeatures: {
    readonly featureFlaggingStatus: string;
  };
  readonly experimentationGettingStartedState: {
    readonly hasAtLeastOneAssignmentSQL: boolean;
    readonly hasAtLeastOneEntity: boolean;
    readonly hasAtLeastOneExperiment: boolean;
    readonly hasAtLeastOneFactSQL: boolean;
    readonly hasAtLeastOneMetric: boolean;
    readonly hasAtLeastOneTeam: boolean;
    readonly hasDataWarehouseConnection: boolean;
  };
  readonly randomizationGettingStartedState: {
    readonly hasAtLeastOneAssignmentSQL: boolean;
    readonly hasAtLeastOneFeatureFlag: boolean;
    readonly hasAtLeastOneFeatureFlagWithAllocation: boolean;
    readonly hasAtLeastOneFeatureFlagWithStatusEnabled: boolean;
    readonly hasAtLeastOneSDKKey: boolean;
    readonly hasAtLeastOneTeam: boolean;
    readonly hasAtLeastOneUsedSDKKey: boolean;
  };
  readonly user: {
    readonly createdAt: string;
    readonly id: string;
    readonly lastClickedUpdates: string | null;
  } | null;
  readonly userCompany: {
    readonly companyId: number;
    readonly isEppoOwned: boolean;
    readonly name: string;
  };
};
export type MenuOptionsSidebar_Query = {
  response: MenuOptionsSidebar_Query$data;
  variables: MenuOptionsSidebar_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEppoOwned",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanyEnabledFeaturesDto",
  "kind": "LinkedField",
  "name": "companyEnabledFeatures",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "featureFlaggingStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "userId",
      "variableName": "userId"
    }
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastClickedUpdates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAtLeastOneAssignmentSQL",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAtLeastOneTeam",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ExperimentationGettingStartedStateDto",
  "kind": "LinkedField",
  "name": "experimentationGettingStartedState",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasDataWarehouseConnection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAtLeastOneEntity",
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAtLeastOneFactSQL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAtLeastOneMetric",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAtLeastOneExperiment",
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "RandomizationGettingStartedStateDto",
  "kind": "LinkedField",
  "name": "randomizationGettingStartedState",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAtLeastOneSDKKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAtLeastOneFeatureFlag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAtLeastOneFeatureFlagWithAllocation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAtLeastOneFeatureFlagWithStatusEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAtLeastOneUsedSDKKey",
      "storageKey": null
    },
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MenuOptionsSidebar_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "userCompany",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v6/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MenuOptionsSidebar_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "userCompany",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v6/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ]
  },
  "params": {
    "cacheID": "fac95f246275c5ed760e64eb2c4c6bbb",
    "id": null,
    "metadata": {},
    "name": "MenuOptionsSidebar_Query",
    "operationKind": "query",
    "text": "query MenuOptionsSidebar_Query(\n  $userId: Int!\n) {\n  userCompany {\n    companyId\n    name\n    isEppoOwned\n    id\n  }\n  companyEnabledFeatures {\n    featureFlaggingStatus\n  }\n  user(userId: $userId) {\n    id\n    lastClickedUpdates\n    createdAt\n  }\n  experimentationGettingStartedState {\n    hasDataWarehouseConnection\n    hasAtLeastOneEntity\n    hasAtLeastOneAssignmentSQL\n    hasAtLeastOneFactSQL\n    hasAtLeastOneMetric\n    hasAtLeastOneExperiment\n    hasAtLeastOneTeam\n  }\n  randomizationGettingStartedState {\n    hasAtLeastOneSDKKey\n    hasAtLeastOneFeatureFlag\n    hasAtLeastOneFeatureFlagWithAllocation\n    hasAtLeastOneFeatureFlagWithStatusEnabled\n    hasAtLeastOneUsedSDKKey\n    hasAtLeastOneAssignmentSQL\n    hasAtLeastOneTeam\n  }\n}\n"
  }
};
})();

(node as any).hash = "b2e2aac2c4b1ffb28d5d1231c69b7180";

export default node;
