import React, { Suspense as ReactSuspense } from 'react';
import { PageLoader } from 'components/loaders/PageLoader';
interface ISuspense {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}
export const Suspense = ({
  children,
  fallback = <PageLoader />
}: ISuspense) => {
  if (!children) {
    return (fallback as JSX.Element);
  }
  return <ReactSuspense fallback={fallback}>{children}</ReactSuspense>;
};