import { EFeatureFlag } from 'constants/featureFlags';

import { useBoolFlagAssignment } from 'components/feature-flag/hooks/useFlagAssignment';

const terminologyMap = {
  allocation: { old: 'allocation', new: 'assignment' },
} as const;

type Terminology = typeof terminologyMap;
type TerminologyKey = keyof Terminology;
type TerminologyValue<T extends TerminologyKey> = Terminology[T]['old'] | Terminology[T]['new'];

export default function useTerminology<T extends TerminologyKey>(term: T): TerminologyValue<T> {
  const isAssignmentRename = useBoolFlagAssignment(EFeatureFlag.AssignmentRename);

  if (isAssignmentRename) {
    return terminologyMap[term].new;
  }

  return terminologyMap[term].old;
}
