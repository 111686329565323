import { ButtonHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { spacing } from 'theme';
import { IconName } from '../icon/icon-map';
import Button, { ButtonVariant } from './Button';
export type IconButtonVariant = Exclude<ButtonVariant, `${string}-outlined`>;
const IconButtonBase = styled(Button)({
  flexShrink: 0,
  height: 'fit-content',
  width: 'fit-content',
  padding: spacing.xs,
  border: 0
});
interface IIconButton extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  icon: IconName | JSX.Element;
  /** @default 'primary-ghost' */
  variant?: IconButtonVariant;
  /** If provided, the Button renders as <a> tag with corresponding styles. */
  href?: string;
  'data-cy-element-type'?: string;
  'data-cy-element-id'?: string;
  'data-cy-link'?: string;
}

/**
 * Primitive UI component for rendering icon-only buttons. Extends {@link Button} and uses the same variants except outlined ones, defaulting to `'primary-ghost'`.
 *
 * [Figma definition](https://www.figma.com/design/2fEScGXkSAxlZaLBCm4ki2/%F0%9F%92%A0-Official-Design-Library?node-id=192-31734&t=vdo5QWOiOwa9Ukgj-4)
 *
 * @example
 *
 * ```tsx
 * // Default icon button.
 * <IconButton icon="Copy" onClick={() => handleCopy()} />
 *
 * // Icon button that acts as a link.
 * <IconButton icon="Gear" href="/admin" />
 *
 * // Secondary ghost icon button.
 * <IconButton icon="Edit" variant="secondary-ghost" />
 *
 * // Filled primary icon button.
 * <IconButton icon="Add circle" variant="primary" />
 *
 * // Grouped buttons with a regular primary button and a separate icon button on the right.
 * <SplitButton>
 *   <Button variant="primary">Save</Button>
 *   <IconButton name="Caret.Down" variant="primary" />
 * </SplitButton>
 * ```
 */
export default function IconButton({
  icon,
  variant = 'primary-ghost',
  href,
  ...props
}: IIconButton) {
  return <IconButtonBase iconLeft={icon} variant={variant} href={href} {...props} />;
}