import { sidebarTransition } from 'constants/animations';

import styled from '@emotion/styled';
import { colors } from 'theme';

import { zIndexFixed } from 'styles/z-index';

export const sidebarExpandedWidth = 200;
export const sidebarCollapsedWidth = 64;

export const SidebarContainer = styled.aside<{ collapsed: boolean }>(({ collapsed }) => ({
  height: '100vh',
  flex: `0 0 ${collapsed ? sidebarCollapsedWidth : sidebarExpandedWidth}px`,
  width: `${collapsed ? sidebarCollapsedWidth : sidebarExpandedWidth}px`,
  transition: sidebarTransition,
  position: 'fixed',
  zIndex: zIndexFixed,
}));

export const SidebarWrapper = styled.nav({
  minHeight: '100vh',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 16px 24px',
  color: colors.text.white,
  backgroundColor: colors.surface.primary,
});

export const SidebarToggleButton = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  padding: 0,
  backgroundColor: 'transparent',
  color: 'inherit',
});
