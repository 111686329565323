import { createContext, useCallback, useEffect, useState } from 'react';

import { noop } from 'helpers/function-helper';

import { useLocalstorage } from './useLocalstorage';

const STORAGE_KEY_COLLAPSED = 'nav-collapsed';
export const NavCollapsedContext = createContext<{
  collapsed: boolean;
  toggleCollapsed: () => void;
}>({ collapsed: false, toggleCollapsed: noop });

export const useNavCollapsed = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { setItem, getItem } = useLocalstorage();

  const setNavCollapsed = useCallback(
    (collapsed: boolean) => {
      setCollapsed(collapsed);
      setItem(STORAGE_KEY_COLLAPSED, collapsed.toString());
    },
    [setItem, setCollapsed],
  );

  useEffect(() => {
    setCollapsed(getItem(STORAGE_KEY_COLLAPSED) === true.toString());
  }, [setCollapsed, getItem]);

  const toggleCollapsed = useCallback(() => {
    setNavCollapsed(!collapsed);
  }, [collapsed, setNavCollapsed]);
  return { NavCollapsedContext, collapsed, toggleCollapsed };
};
