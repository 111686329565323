import { COLOR_GREY_200 } from 'constants/colors';

import styled from '@emotion/styled';

export const DividerLine = styled.div(() => ({
  width: '100%',
  height: '1px',
  boxShadow: `0px -1px 0px 0px ${COLOR_GREY_200} inset`,
}));

export const DividerVerticalLine = styled.div(() => ({
  width: '1px',
  height: '100',
  background: COLOR_GREY_200,
}));
