import Script from 'next/script';
declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Intercom: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}
function renderScript(appId: string) {
  return `(function(){
    var w=window;
    var ic=w.Intercom;
    if (typeof ic==="function") {
      ic('reattach_activator');
      ic('update',w.intercomSettings);
    } else {
      var d=document;
      var i=function() {
        i.c(arguments);
      };
      i.q=[];
      i.c=function(args) {
        i.q.push(args);
      };
      w.Intercom=i;
      var l=function() {
        var s=d.createElement('script');
        s.type='text/javascript';
        s.defer=true;
        s.src='https://widget.intercom.io/widget/${appId}';
        var x=d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if(document.readyState==='complete'){
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload',l);
      } else {
        w.addEventListener('load',l,false);
      }
    }
  })();`;
}
export default function IntercomScript() {
  const appId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
  return appId ? <Script id="intercom-script" dangerouslySetInnerHTML={{
    __html: renderScript(appId)
  }} /> : null;
}