import { useEffect, useMemo } from 'react';
import { SPACE_L } from 'constants/sizes';
import debounce from 'lodash/debounce';
import { FormProvider, useForm } from 'react-hook-form';
import { useFragment, useMutation } from 'react-relay';
import { graphql } from 'relay-runtime';
import { DividerLine } from 'components/divider/DividerLine';
import FormCheckboxInput from 'components/inputs/hook-form/FormCheckboxInput';
import FormToggle from 'components/inputs/hook-form/FormToggle';
import { FlexBox } from 'components/style-components/FlexBox';
import { FlexBoxItem } from 'components/style-components/FlexBoxItem';
import Hint from 'components/typography/Hint';
import Icon from 'components/ui/icon/Icon';
import { Box } from 'components/ui/layout/Box';
import useTerminology from 'helpers/useTerminology';
import { useCustomToasts } from 'hooks/useCustomToasts';
import { EmailNotificationsSettingsEmail, EmailNotificationsSettingsLabel, EmailNotificationsSettingsTitle, EmailNotificationsSettingsWrapper } from './styled';
import { UserEmailNotificationsSettings_Fragment$key } from './__generated__/UserEmailNotificationsSettings_Fragment.graphql';
import { UserEmailNotificationsSettings_Update_Mutation } from './__generated__/UserEmailNotificationsSettings_Update_Mutation.graphql';
const EMAIL_NOTIFICATION_SETTINGS_FRAGMENT = graphql`
  fragment UserEmailNotificationsSettings_Fragment on Query {
    userNotificationEmailSettings {
      id
      enabled
      settings {
        onExperimentDataProblem
        onExperimentStatusChange
        onSequentialExperimentMetricStatisticalSignificance
        onTeamMetricStatisticalSignificance
        onTeamFeatureFlagEnableDisable
        onTeamFeatureFlagInProdAllocationUpdate
      }
    }
  }
`;
const UPDATE_EMAIL_NOTIFICATION_SETTINGS_MUTATION = graphql`
  mutation UserEmailNotificationsSettings_Update_Mutation(
    $input: UpdateUserEmailNotificationSettingsInput!
  ) {
    updateUserEmailNotificationSettings(input: $input) {
      id
      enabled
      settings {
        onExperimentDataProblem
        onExperimentStatusChange
        onSequentialExperimentMetricStatisticalSignificance
        onTeamMetricStatisticalSignificance
        onTeamFeatureFlagEnableDisable
        onTeamFeatureFlagInProdAllocationUpdate
      }
    }
  }
`;
interface IEmailNotificationsSetting {
  email: string;
  fragmentRef: UserEmailNotificationsSettings_Fragment$key;
}
export default function UserEmailNotificationsSettings({
  email,
  fragmentRef
}: IEmailNotificationsSetting) {
  const {
    addToast
  } = useCustomToasts();
  const {
    userNotificationEmailSettings: notificationSettings
  } = useFragment(EMAIL_NOTIFICATION_SETTINGS_FRAGMENT, fragmentRef);
  const [updateSettings] = useMutation<UserEmailNotificationsSettings_Update_Mutation>(UPDATE_EMAIL_NOTIFICATION_SETTINGS_MUTATION);
  const allocationTerm = useTerminology('allocation');
  const form = useForm<IFormData>({
    defaultValues: {
      enabled: notificationSettings.enabled,
      onExperimentDataProblem: notificationSettings.settings.onExperimentDataProblem,
      onExperimentStatusChange: notificationSettings.settings.onExperimentStatusChange || false,
      onSequentialExperimentMetricStatisticalSignificance: notificationSettings.settings.onSequentialExperimentMetricStatisticalSignificance || false,
      onTeamMetricStatisticalSignificance: notificationSettings.settings.onTeamMetricStatisticalSignificance || false,
      onTeamFeatureFlagEnableDisable: notificationSettings.settings.onTeamFeatureFlagEnableDisable || false,
      onTeamFeatureFlagInProdAllocationUpdate: notificationSettings.settings.onTeamFeatureFlagInProdAllocationUpdate || false
    }
  });
  const {
    handleSubmit,
    watch,
    reset
  } = form;

  // Manually trigger validation and submission (if valid) for each field on change
  const onSubmit = useMemo(() => {
    return debounce((data: IFormData) => {
      updateSettings({
        variables: {
          input: data
        },
        onError: () => {
          addToast('Error updating email notification settings', 'error');
          reset();
        }
      });
    }, 1000); // clicking checkboxes is faster than 500ms
  }, [updateSettings, addToast, reset]);

  // Manually trigger validation and submission (if valid) for each field on change
  useEffect(() => {
    const subscription = watch((_data, {
      type
    }) => {
      // check for change, so we can reset the form onError
      if (type === 'change') {
        handleSubmit(onSubmit)();
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onSubmit]);
  const enabled = watch('enabled');
  const onExperimentDataProblem = watch('onExperimentDataProblem');
  const onExperimentStatusChange = watch('onExperimentStatusChange');
  const onSequentialExperimentMetricStatisticalSignificance = watch('onSequentialExperimentMetricStatisticalSignificance');
  const onTeamMetricStatisticalSignificance = watch('onTeamMetricStatisticalSignificance');
  const onTeamFeatureFlagEnableDisable = watch('onTeamFeatureFlagEnableDisable');
  const onTeamFeatureFlagInProdAllocationUpdate = watch('onTeamFeatureFlagInProdAllocationUpdate');
  return <FormProvider {...form}>
      <EmailNotificationsSettingsWrapper>
        <FlexBox flexDirection="row" alignItems="center" justifyContent="space-between" margin="8px 0 0 0">
          <Icon name="Mail" />
          <Box margin="0 24px 0 16px">
            <EmailNotificationsSettingsEmail>{email}</EmailNotificationsSettingsEmail>
          </Box>
          <FlexBoxItem flexGrow={1} />
          <FormToggle name="enabled" />
        </FlexBox>
        <Box margin={`${SPACE_L}px 0 ${SPACE_L}px 0`}>
          <DividerLine />
        </Box>
        <EmailNotificationsSettingsTitle>
          For experiments owned by my team(s)
        </EmailNotificationsSettingsTitle>
        <FlexBox margin="8px 0 0 4px" alignItems="center">
          <FormCheckboxInput name="onExperimentDataProblem" isDisabled={!enabled} register={form.register} value={onExperimentDataProblem} />
          <Box margin="0 0 0 16px">
            <EmailNotificationsSettingsLabel>
              A data problem on any experiment
            </EmailNotificationsSettingsLabel>
          </Box>
        </FlexBox>
        <Box margin="0 0 0 36px">
          <Hint>Traffic imbalances and experiment refresh errors</Hint>
        </Box>
        <FlexBox margin="8px 0 0 4px" alignItems="center">
          <FormCheckboxInput name="onExperimentStatusChange" isDisabled={!enabled} register={form.register} value={onExperimentStatusChange} />
          <Box margin="0 0 0 16px">
            <EmailNotificationsSettingsLabel>
              A status change on an experiment
            </EmailNotificationsSettingsLabel>
          </Box>
        </FlexBox>
        <FlexBox margin="8px 0 16px 4px" alignItems="center">
          <FormCheckboxInput name="onSequentialExperimentMetricStatisticalSignificance" isDisabled={!enabled} register={form.register} value={onSequentialExperimentMetricStatisticalSignificance} />
          <Box margin="0 0 0 16px">
            <EmailNotificationsSettingsLabel>
              A primary or guardrail metric reached statistical significance on a sequential
              experiment
            </EmailNotificationsSettingsLabel>
          </Box>
        </FlexBox>
        <EmailNotificationsSettingsTitle>
          For metrics owned by my team(s)
        </EmailNotificationsSettingsTitle>
        <FlexBox margin="8px 0 16px 4px" alignItems="center">
          <FormCheckboxInput name="onTeamMetricStatisticalSignificance" isDisabled={!enabled} register={form.register} value={onTeamMetricStatisticalSignificance} />
          <Box margin="0 0 0 16px">
            <EmailNotificationsSettingsLabel>
              A metric reached statistical significance on any team’s sequential experiment
            </EmailNotificationsSettingsLabel>
          </Box>
        </FlexBox>
        <EmailNotificationsSettingsTitle>
          For flags owned by my team(s)
        </EmailNotificationsSettingsTitle>
        <FlexBox margin="8px 0 0 4px" alignItems="center">
          <FormCheckboxInput name="onTeamFeatureFlagEnableDisable" isDisabled={!enabled} register={form.register} value={onTeamFeatureFlagEnableDisable} />
          <Box margin="0 0 0 16px">
            <EmailNotificationsSettingsLabel>
              A flag is enabled or disabled in production environment
            </EmailNotificationsSettingsLabel>
          </Box>
        </FlexBox>
        <FlexBox margin="8px 0 16px 4px" alignItems="center">
          <FormCheckboxInput name="onTeamFeatureFlagInProdAllocationUpdate" isDisabled={!enabled} register={form.register} value={onTeamFeatureFlagInProdAllocationUpdate} />
          <Box margin="0 0 0 16px">
            <EmailNotificationsSettingsLabel>
              A new {allocationTerm} is added, edited, or deleted to a flag in production
              environment
            </EmailNotificationsSettingsLabel>
          </Box>
        </FlexBox>
      </EmailNotificationsSettingsWrapper>
    </FormProvider>;
}
interface IFormData {
  enabled: boolean;
  onExperimentDataProblem: boolean;
  onExperimentStatusChange: boolean;
  onSequentialExperimentMetricStatisticalSignificance: boolean;
  onTeamMetricStatisticalSignificance: boolean;
  onTeamFeatureFlagEnableDisable: boolean;
  onTeamFeatureFlagInProdAllocationUpdate: boolean;
}