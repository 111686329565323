import { sidebarTransition } from 'constants/animations';
import { COLOR_WHITE, COLOR_GREY_200, COLOR_GREY_100 } from 'constants/colors';
import { HEADER_HEIGHT } from 'constants/sizes';

import styled from '@emotion/styled';
import Link from 'next/link';
import { borderRadius, colors, container, spacing } from 'theme';

import { sidebarCollapsedWidth, sidebarExpandedWidth } from 'components/layout/nav/styled';

import { zIndexSticky } from 'styles/z-index';

export const AuthenticatedPageLayoutContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  margin: 0,
  padding: 0,
  height: '100%',
  width: '100%',
});

export const PageContainer = styled.main<{ navCollapsed: boolean }>(({ navCollapsed }) => ({
  position: 'relative',
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: navCollapsed ? sidebarCollapsedWidth : sidebarExpandedWidth,
  transition: sidebarTransition,
  minHeight: '100vh',
  overflow: 'hidden',
}));

export const HeaderContainer = styled.div<{ isSticky: boolean }>(({ isSticky }) => ({
  width: '100%',
  position: isSticky ? 'sticky' : 'relative',
  top: 0,
  background: COLOR_WHITE,
  zIndex: isSticky ? zIndexSticky : 'auto',
  padding: 0,
}));

export const BackgroundWrapper = styled.div({
  height: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.surface.primary,
  overflowX: 'auto',
});

export const LayoutCurve = styled.div<{ navCollapsed?: boolean }>(({ navCollapsed = false }) => ({
  position: 'relative',
  minWidth: '100%',
  height: `calc(100vh - ${HEADER_HEIGHT})`,
  backgroundColor: colors.background.primary,

  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  borderTopLeftRadius: borderRadius.lg,

  '&::before': {
    content: "''",
    position: 'fixed',
    top: HEADER_HEIGHT,
    left: navCollapsed ? sidebarCollapsedWidth : sidebarExpandedWidth,
    right: 0,
    bottom: 0,
    borderTopLeftRadius: borderRadius.lg,
    boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.16) inset',
    pointerEvents: 'none',
    transition: sidebarTransition,
  },
}));

export const ContentWrapper = styled.div({
  minWidth: container.minWidth,
  flex: '1 1 auto',
  display: 'flex',
  overflowY: 'auto',
  flexDirection: 'column',
  backgroundColor: colors.background.primary,
  paddingBottom: '60px',
});

export const Header = styled.div(() => ({
  position: 'relative',
  height: 50,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${COLOR_GREY_100}`,
}));

export const HeaderTitleWrapper = styled.div(() => ({
  display: 'flex',
}));

export const BreadcrumbLinkV2 = styled(Link)({
  color: colors.text.primary,
  '&:hover': {
    color: colors.text.link,
    textDecoration: 'none',
  },
});

export const BreadcrumbCaret = styled.div(() => ({
  transform: 'rotate(-90deg)',
}));

export const HeaderControls = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'sticky',
}));

export const ErrorFallbackWrapper = styled.div(() => ({
  margin: '20vh auto',
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: spacing.lg,

  img: {
    width: 200,
    height: 200,
  },
}));

export const SubheaderMenuButtons = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
}));

export const SubheaderMenuButton = styled.div<{ isSelected?: boolean }>(
  ({ isSelected = false }) => ({
    fontSize: 13,
    lineHeight: '16px',
    color: colors.text.primary,
    background: isSelected ? colors.hover.tertiary : COLOR_WHITE,
    borderRadius: 8,
    textTransform: 'capitalize',
    marginRight: spacing.sm,
    padding: spacing.sm,
    cursor: 'pointer',
  }),
);

export const HeaderControl = styled.div<{ paddingLeft?: number | string }>(
  ({ paddingLeft = 28 }) => ({
    paddingLeft,
  }),
);

export const SubHeaderMenuItem = styled.div<{ hideBorder?: boolean }>(({ hideBorder = false }) => ({
  borderLeft: hideBorder ? 'none' : `1px solid ${COLOR_GREY_200}`,
  padding: `0 ${spacing.lg}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:last-of-type': {
    padding: `0 0 0 ${spacing.lg}`,
  },
}));

export const HeaderCardWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 130,
  height: 50,
  justifyContent: 'space-between',
  fontSize: 13,
}));

export const Section = styled.section({
  margin: `${spacing.lg} 0`,
  padding: spacing.lg,
});
