import { RecordSourceSelectorProxy } from 'relay-runtime';

export function fromGlobalId(globalId: string): number {
  if (!globalId) {
    return NaN;
  }
  const decodedGlobalId = atob(globalId);
  const id = decodedGlobalId.split(':')[1];

  return parseInt(id, 10);
}

export function createGlobalId(type: string, id: number): string {
  return Buffer.from(`${type}:${id}`).toString('base64');
}

export function appendToStoreListFromResponse(
  store: RecordSourceSelectorProxy,
  responseField: string,
  linkedRecordsField: string,
  addedIndex = -1,
  args?: Record<string, string | number | boolean>,
) {
  const response = store.getRootField(responseField);
  const root = store.getRoot();
  const linkedRecords = root.getLinkedRecords(linkedRecordsField, args);

  if (!linkedRecords || !response) return;

  if (addedIndex > -1) {
    if (linkedRecords[addedIndex] !== null) {
      root.setLinkedRecords(
        [...linkedRecords.slice(0, addedIndex), response, ...linkedRecords.slice(addedIndex)],
        linkedRecordsField,
        args,
      );
    } else {
      linkedRecords[addedIndex] = response;
      root.setLinkedRecords([...linkedRecords], linkedRecordsField, args);
    }
  } else {
    root.setLinkedRecords([...linkedRecords, response], linkedRecordsField, args);
  }
}

export function cleanDeletedRecords(
  store: RecordSourceSelectorProxy,
  linkedRecordsField: string,
  args?: Record<string, string | number | boolean>,
) {
  const root = store.getRoot();
  const linkedRecords = root.getLinkedRecords(linkedRecordsField, args);

  if (!linkedRecords) return;

  root.setLinkedRecords(
    linkedRecords.filter((item) => !!item),
    linkedRecordsField,
    args,
  );
}

export function appendToParentStoreListFromResponse(
  store: RecordSourceSelectorProxy,
  responseField: string,
  parentRecordField: string,
  parentArgs: Record<string, string | number | boolean>,
  linkedRecordsField: string,
  addedIndex = -1,
) {
  const response = store.getRootField(responseField);
  const root = store.getRoot();
  const parent = root.getLinkedRecord(parentRecordField, parentArgs);

  if (!parent) {
    return;
  }

  const linkedRecords = parent.getLinkedRecords(linkedRecordsField);

  if (!linkedRecords || !response) return;

  if (addedIndex > -1) {
    if (linkedRecords[addedIndex] !== null) {
      throw new Error('Error appending item. Item with this index already exists');
    }
    linkedRecords[addedIndex] = response;
    parent.setLinkedRecords([...linkedRecords], linkedRecordsField);
  } else {
    parent.setLinkedRecords([...linkedRecords, response], linkedRecordsField);
  }
}

export function cleanParentDeletedRecords(
  store: RecordSourceSelectorProxy,
  parentRecordField: string,
  parentArgs: Record<string, string | number | boolean>,
  linkedRecordsField: string,
) {
  const root = store.getRoot();
  const parent = root.getLinkedRecord(parentRecordField, parentArgs);

  if (!parent) {
    return;
  }
  const links = parent.getLinkedRecords(linkedRecordsField);

  if (!links) {
    return;
  }

  parent.setLinkedRecords(
    links.filter((item) => !!item),
    linkedRecordsField,
  );
}
