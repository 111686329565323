import styled from '@emotion/styled';
import { Property } from 'csstype';
export type IFlexBoxItem = {
  alignSelf?: Property.AlignSelf;
  flexGrow?: Property.FlexGrow;
  flexShrink?: Property.FlexShrink;
  flexBasis?: Property.FlexBasis;
  flex?: Property.Flex;
  marginLeft?: Property.Margin;
  marginRight?: Property.Margin;
  width?: Property.Width;
  height?: Property.Height;
};
export const FlexBoxItem = styled.div<IFlexBoxItem>((args: IFlexBoxItem) => ({
  alignSelf: args.alignSelf,
  flexGrow: args.flexGrow,
  flexShrink: args.flexShrink,
  flexBasis: args.flexBasis,
  flex: args.flex,
  marginLeft: args.marginLeft,
  marginRight: args.marginRight,
  width: args.width,
  height: args.height
}));