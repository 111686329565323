/* eslint-disable @typescript-eslint/ban-ts-comment */

import { datadogRum } from '@datadog/browser-rum';

let isInitialized = false;

export function isDatadogRumInitialized() {
  return isInitialized;
}

export function initDatadogRum() {
  if (isInitialized) {
    return;
  }

  const datadogAppId = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
  const datadogClientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
  if (datadogAppId && datadogClientToken) {
    datadogRum.init({
      applicationId: datadogAppId,
      clientToken: datadogClientToken,
      site: 'datadoghq.com',
      service: 'eppo-frontend',
      env: process.env.NEXT_PUBLIC_APP_ENV,
      version: process.env.NEXT_PUBLIC_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: process.env.NEXT_PUBLIC_API_BASE_URL
        ? [process.env.NEXT_PUBLIC_API_BASE_URL]
        : undefined,
      enableExperimentalFeatures: ['feature_flags'],
    });

    datadogRum.startSessionReplayRecording();

    isInitialized = true;
  }
}
