// primary color
export const COLOR_PRIMARY = '#1A8FAB';

// primary color variants
export const COLOR_PRIMARY_100 = '#F0FBFE';
export const COLOR_PRIMARY_200 = '#DFF7FD';
export const COLOR_PRIMARY_300 = '#B5EBF9';
export const COLOR_PRIMARY_400 = '#72D2E9';
export const COLOR_PRIMARY_500 = '#3BAFCB';
export const COLOR_PRIMARY_600 = '#1A8FAB';
export const COLOR_PRIMARY_700 = '#10768E';
export const COLOR_PRIMARY_800 = '#085569';
export const COLOR_PRIMARY_900 = '#2B3C40';

// secondary color
export const COLOR_SECONDARY = '#9C4096';
export const COLOR_SECONDARY_NAV = '#C6C1F1';

// secondary color variants
export const COLOR_SECONDARY_100 = '#FCF6FB';
export const COLOR_SECONDARY_200 = '#F8E8F7';
export const COLOR_SECONDARY_300 = '#EECEEC';
export const COLOR_SECONDARY_400 = '#D89FD4';
export const COLOR_SECONDARY_500 = '#C16FBC';
export const COLOR_SECONDARY_600 = '#9C4096';
export const COLOR_SECONDARY_700 = '#752570';
export const COLOR_SECONDARY_800 = '#4C0F49';
export const COLOR_SECONDARY_900 = '#2A0727';
export const COLOR_SECONDARY_1000 = '#7964d3';

// grey color variants
export const COLOR_GREY_100 = '#F6F8FA';
export const COLOR_GREY_200 = '#EEF2F6';
export const COLOR_GREY_300 = '#DAE1E6';
export const COLOR_GREY_400 = '#BBC4CD';
export const COLOR_GREY_500 = '#9DA7B2';
export const COLOR_GREY_600 = '#7D8994';
export const COLOR_GREY_700 = '#5A6874';
export const COLOR_GREY_800 = '#2D3740';
export const COLOR_GREY_900 = '#1C2329';

// grey color
export const COLOR_GREY = COLOR_GREY_700;

// text color
export const COLOR_BODY = COLOR_GREY_900;

// nav
export const COLOR_NAV = COLOR_GREY_900;

// borders
export const COLOR_BORDER = COLOR_GREY_300;

// gradient
export const COLOR_LINER_GRADIENT =
  'linear-gradient(270deg, #1B8FAB 17%, #2D99DA 51%, #752470 87%)';

// overlay
export const COLOR_DARKENED_OVERLAY = 'rgba(0, 0, 0, 0.25)';

// others
export const COLOR_BLACK = '#000000';
export const COLOR_DARK = COLOR_GREY_900;
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_RED = '#FF0000';
export const COLOR_ERROR = '#E23A59';
export const COLOR_INFO = '#E7F1FA';
export const COLOR_INFO_100 = '#E7F1FA';
export const COLOR_INFO_200 = '#CDE5FC';
export const COLOR_INFO_300 = '#A7CDF0';
export const COLOR_INFO_400 = '#6DACE8';
export const COLOR_INFO_500 = '#3B8BD5';
export const COLOR_INFO_600 = '#256EB2';
export const COLOR_LINK = '#1A8FAB';
export const COLOR_SUCCESS = '#21C19B';
export const COLOR_SUCCESS_100 = '#E4F7F2';
export const COLOR_SUCCESS_200 = '#C9F4EA';
export const COLOR_SUCCESS_300 = '#9EE6D5';
export const COLOR_SUCCESS_500 = '#21C19B';
export const COLOR_WARN = '#FFA400';
export const COLOR_WARN_100 = '#FDF5E4';
export const COLOR_WARN_200 = '#FFEAC5';
export const COLOR_WARN_300 = '#FFD383';
export const COLOR_WARN_500 = '#FFA400';
export const COLOR_ERROR_LIGHT = '#FFDDE3';
export const COLOR_INFO_SUCCESS = '#E4F7F2';

// Violet
export const COLOR_VIOLET_100 = '#E4E0F6';
export const COLOR_VIOLET_600 = '#8674D7';

// Pink
export const COLOR_PINK_100 = '#FDEDF9';

// Teal
export const COLOR_TEAL_100 = '#25C4C5';
export const COLOR_TEAL_600 = '#E9F9F9';

// Orange
export const COLOR_ORANGE = '#DE8F00';

// Categorical Colors
export const COLOR_CAT_1 = '#9C3F96';
export const COLOR_CAT_2 = '#F67206';
export const COLOR_CAT_3 = '#07B0FB';
export const COLOR_CAT_4 = '#EC4BC2';
export const COLOR_CAT_5 = '#1F2CAA';
export const COLOR_CAT_6 = '#76934E';
export const COLOR_CAT_7 = '#B81C73';
export const COLOR_CAT_8 = '#25C4C5';
export const COLOR_CAT_9 = '#F5B11E';
export const COLOR_CAT_10 = '#718DBD';

export const CATEGORY_COLORS = [
  COLOR_CAT_1,
  COLOR_CAT_2,
  COLOR_CAT_3,
  COLOR_CAT_4,
  COLOR_CAT_5,
  COLOR_CAT_6,
  COLOR_CAT_7,
  COLOR_CAT_8,
  COLOR_CAT_9,
  COLOR_CAT_10,
];

// Chart colors
export const COLOR_CHART_1 = '#7257F6';
export const COLOR_CHART_2 = '#97DFD8';
export const COLOR_CHART_3 = '#EE7D61';
export const COLOR_CHART_4 = '#C083D8';
export const COLOR_CHART_5 = '#EFBE55';
export const COLOR_CHART_6 = '#5BA778';
export const COLOR_CHART_7 = '#A65D6E';
export const COLOR_CHART_8 = '#F4B583';
export const COLOR_CHART_9 = '#73B5B0';
export const COLOR_CHART_10 = '#5F5CB1';
export const COLOR_CHART_11 = '#83BBEF';
export const COLOR_CHART_12 = '#F4BEB4';

export const CHART_COLORS = [
  COLOR_CHART_1,
  COLOR_CHART_2,
  COLOR_CHART_3,
  COLOR_CHART_4,
  COLOR_CHART_5,
  COLOR_CHART_6,
  COLOR_CHART_7,
  COLOR_CHART_8,
  COLOR_CHART_9,
  COLOR_CHART_10,
  COLOR_CHART_11,
  COLOR_CHART_12,
];

export const UNCATEGORIZED_COLOR = '#F5ECF4';
export const COLOR_CODE = '#EB5757';

export const CONTROL_VARIATION_COLOR = '#718DBD';
export const TEAM_ICON_BACKGROUND = COLOR_CAT_10;

// don't use these directly, these are used by the modifyColorOpacity helpers.
export const COLOR_OPACITY_PERCENT_10 = '19';
export const COLOR_OPACITY_PERCENT_20 = '33';

// these transformations can be used to change a black icon into a different color
export const TRANSFORM_BLACK_TO_WHITE = 'invert(100%)';
export const TRANSFORM_BLACK_TO_BODY =
  'invert(5%) sepia(6%) saturate(5053%) hue-rotate(166deg) brightness(104%) contrast(82%)';
export const TRANSFORM_BLACK_TO_TEXT_PRIMARY =
  'invert(8%) sepia(5%) saturate(973%) hue-rotate(349deg) brightness(97%) contrast(85%)';
export const TRANSFORM_BLACK_TO_TEXT_SECONDARY =
  'invert(44%) sepia(8%) saturate(514%) hue-rotate(357deg) brightness(92%) contrast(83%)';
