import { COLOR_ERROR } from 'constants/colors';
import {
  FONT_SIZE_LARGE,
  FONT_SIZE_MEDIUM,
  FONT_SIZE_REGULAR,
  FONT_SIZE_SMALL,
} from 'constants/typography';

import styled from '@emotion/styled';
import { Property } from 'csstype';

import { colors } from 'theme/colors';

export enum FontFamilyEnum {
  DEFAULT = '"Cofo Sans", sans-serif',
  MONOSPACE = '"Cofo Sans Mono", monospace',
}

export type ITypography = {
  color?: string;
  fontFamily?: FontFamilyEnum;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLHeadingElement>;
};

export type TextType = 'default' | 'small' | 'medium' | 'large';

const fontSizes = new Map<TextType, number>([
  ['default', FONT_SIZE_REGULAR],
  ['small', FONT_SIZE_SMALL],
  ['medium', FONT_SIZE_MEDIUM],
  ['large', FONT_SIZE_LARGE],
]);

const lineHeights = new Map<TextType, string>([
  ['default', '16px'],
  ['small', '14px'],
  ['medium', '18px'],
  ['large', '21px'],
]);

type ITextProps = {
  type?: TextType;
  bold?: boolean;
  italic?: boolean;
  noWrap?: boolean;
  margin?: string;
  padding?: string;
  underlineOnHover?: boolean;
  textTransform?: Property.TextTransform;
} & ITypography;
const getTextStyle = ({
  type = 'default',
  bold,
  color = colors.text.primary,
  italic,
  noWrap = false,
  margin,
  padding,
  fontFamily = FontFamilyEnum.DEFAULT,
  underlineOnHover = false,
  textTransform,
}: ITextProps) => ({
  fontWeight: bold ? 700 : 400,
  fontSize: fontSizes.get(type),
  lineHeight: lineHeights.get(type),
  letterSpacing: -0.2,
  color: color,
  whiteSpace: noWrap ? 'nowrap' : ('normal' as 'nowrap' | 'normal'),
  fontStyle: italic ? 'italic' : 'None',
  fontFamily: fontFamily,
  margin: margin ? margin : 0,
  padding: padding ? padding : 0,
  textTransform,
  '&:hover': {
    textDecoration: underlineOnHover ? 'underline' : 'none',
  },
});
export const TextDivContainer = styled.div<ITextProps>(getTextStyle);
export const PreContainer = styled.pre<ITextProps>(getTextStyle);

export const Required = styled.span({
  color: COLOR_ERROR,
});

export const NoNewLineWrapper = styled.span({
  whiteSpace: 'nowrap',
});

export const HintWrapper = styled.span({
  fontSize: FONT_SIZE_SMALL,
  color: colors.text.secondary,
});
