import dynamic from 'next/dynamic';
import * as animationData from './lottie/loader-with-dots.json';
import { iconSizes } from './styled';
const Lottie = dynamic(() => import('lottie-react'), {
  ssr: false
});
export interface ILoaderWithDots {
  size?: iconSizes;
  color?: string;
  marginRight?: number;
}
export default function LoaderWithDots({
  size = 20,
  color = 'currentColor',
  marginRight
}: ILoaderWithDots) {
  return <Lottie className="loader-with-dots" css={{
    overflow: 'visible',
    height: size,
    width: size,
    marginRight,
    '& g, & path': {
      fill: color
    }
  }} loop={true} autoPlay={true} animationData={animationData} />;
}