import { COLOR_WHITE } from 'constants/colors';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Heading from '../ui/typography/Heading';
import eppoLogo from './img/eppo-logo-purple.svg';
import { LoginPageColumn, LoginPageForm, LoginPageFormContainerP, LoginPageFormWrapper, LoginPageHref, LoginPageLayout, LoginPageWrapper, LogoWrapper } from './styled';
export default function LoginPage() {
  const router = useRouter();
  const next = router.query['next'];

  // If path contains ?next= query param, pass it on to Auth0 for post-login redirection.
  const loginUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL || ''}/login${next ? `?next=${next}` : ''}`;
  const termsUrl = `https://app.termly.io/document/terms-of-use-for-saas/4c635cc8-24f6-4c05-83fa-0382fca756ce`;
  const privacyPolicyUrl = `https://app.termly.io/document/privacy-policy/a555478b-524f-4b53-b70e-6575d94ad3c7`;
  return <LoginPageLayout>
      {/* TODO: Replace the old logo with <EppoLogo color="dark" height="32px" /> once we publicly rollout the Rebrand M1. */}
      <LogoWrapper>
        <img src={eppoLogo} alt="Eppo" />
      </LogoWrapper>
      <LoginPageWrapper>
        <LoginPageColumn>
          <LoginPageFormWrapper>
            <Heading variant="h3">Log in or Sign up</Heading>

            <LoginPageForm>
              <Link href={loginUrl} prefetch={false}>
                <LoginButton>Continue to Eppo</LoginButton>
              </Link>
              <LoginPageFormContainerP>
                By continuing, you agree to Eppo’s{' '}
                <LoginPageHref href={termsUrl} target="_blank">
                  Terms of Use
                </LoginPageHref>
                . Read our{' '}
                <LoginPageHref href={privacyPolicyUrl} target="_blank">
                  Privacy Policy.
                </LoginPageHref>
              </LoginPageFormContainerP>
            </LoginPageForm>
          </LoginPageFormWrapper>
        </LoginPageColumn>
      </LoginPageWrapper>
    </LoginPageLayout>;
}
const LoginButton = styled.button({
  width: '336px',
  height: '48px',
  fontSize: '13px',
  color: COLOR_WHITE,
  backgroundColor: '#6C55D4',
  // matches the logo. refactor after M1 launch
  borderRadius: '8px',
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 12px'
});