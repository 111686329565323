import { borderRadius } from './borderRadius';
import { boxShadow } from './boxShadow';
import { colors } from './colors';
import { container } from './container';
import { fontSize } from './fontSize';
import { fontWeight } from './fontWeight';
import { lineHeight } from './lineHeight';
import { spacing } from './spacing';

export { colors, container, borderRadius, spacing, fontSize, fontWeight, lineHeight, boxShadow };
