/** Figma definition: [link](https://www.figma.com/design/2fEScGXkSAxlZaLBCm4ki2/%F0%9F%92%A0-Official-Design-Library?node-id=3232-2521&m=dev). */
export const fontSize = {
  body: '14px',
  bodySmall: '13px',
  bodyExtraSmall: '12px',

  h1: '26px',
  h2: '21px',
  h3: '16px',
} as const;
