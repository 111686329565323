// eslint-disable-next-line @typescript-eslint/no-explicit-any
let rudderanalytics: any;

async function rudderInitialize() {
  if (typeof window === 'undefined' || window === null) {
    return;
  }

  rudderanalytics = await import('rudder-sdk-js');

  const writeKey = process.env.NEXT_PUBLIC_RUDDERSTACK_KEY;

  if (writeKey) {
    rudderanalytics.load(writeKey, 'https://geteppojohdkq.dataplane.rudderstack.com');
  }
}

if (!rudderanalytics) {
  rudderInitialize();
}

function page() {
  rudderanalytics && rudderanalytics.page();
}

function track(eventName: string, meta?: object) {
  rudderanalytics && rudderanalytics.track(eventName, meta);
}

export { rudderanalytics, page, track };
