import { createContext, useEffect, useState } from 'react';
import { generateRbacPermissions, RbacPermissionsRecord } from 'hooks/useRbacPermissions';
import { useUser } from 'hooks/useUser';
export const RbacPermissionsContext = createContext<{
  rbacPermissions: RbacPermissionsRecord;
}>({
  // This default permissions will be overwritten before any content is rendered
  rbacPermissions: generateRbacPermissions([])
});
interface IRbacPermissionsProvider {
  children: JSX.Element;
}
export function RbacPermissionsProvider({
  children
}: IRbacPermissionsProvider) {
  const {
    permissions
  } = useUser();
  const [rbacPermissions, setRbacPermissions] = useState(generateRbacPermissions(permissions));
  useEffect(() => setRbacPermissions(generateRbacPermissions(permissions)), [permissions]);
  return <RbacPermissionsContext.Provider value={{
    rbacPermissions
  }}>
      {children}
    </RbacPermissionsContext.Provider>;
}