import { COLOR_ERROR, COLOR_GREY_300 } from 'constants/colors';
import { SPACE_L } from 'constants/sizes';
import { FONT_SIZE_REGULAR, FONT_SIZE_SMALL } from 'constants/typography';

import styled from '@emotion/styled';

import { colors } from 'theme/colors';

export const EmailNotificationsSettingsWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: SPACE_L,
  minHeight: 64,
  border: `1px solid ${COLOR_GREY_300}`,
  alignItems: 'flex-start',
}));

export const EmailNotificationsSettingsTitle = styled.span(() => ({
  fontSize: FONT_SIZE_REGULAR,
  fontWeight: 500,
  color: colors.text.primary,
}));

export const EmailNotificationsSettingsEmail = styled.span(() => ({
  fontSize: FONT_SIZE_REGULAR,
  color: colors.text.primary,
}));

export const EmailNotificationsSettingsError = styled.span(() => ({
  fontSize: FONT_SIZE_SMALL,
  color: COLOR_ERROR,
}));

export const EmailNotificationsSettingsLabel = styled.label(() => ({
  fontSize: FONT_SIZE_REGULAR,
}));
