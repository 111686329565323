/**
 * @generated SignedSource<<42fb8f6556a9dc1a4b1a05183e206021>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserEmailNotificationSettingsInput = {
  enabled: boolean;
  onExperimentDataProblem: boolean;
  onExperimentStatusChange: boolean;
  onSequentialExperimentMetricStatisticalSignificance: boolean;
  onTeamFeatureFlagEnableDisable: boolean;
  onTeamFeatureFlagInProdAllocationUpdate: boolean;
  onTeamMetricStatisticalSignificance: boolean;
};
export type UserEmailNotificationsSettings_Update_Mutation$variables = {
  input: UpdateUserEmailNotificationSettingsInput;
};
export type UserEmailNotificationsSettings_Update_Mutation$data = {
  readonly updateUserEmailNotificationSettings: {
    readonly enabled: boolean;
    readonly id: string;
    readonly settings: {
      readonly onExperimentDataProblem: boolean;
      readonly onExperimentStatusChange: boolean | null;
      readonly onSequentialExperimentMetricStatisticalSignificance: boolean | null;
      readonly onTeamFeatureFlagEnableDisable: boolean | null;
      readonly onTeamFeatureFlagInProdAllocationUpdate: boolean | null;
      readonly onTeamMetricStatisticalSignificance: boolean | null;
    };
  };
};
export type UserEmailNotificationsSettings_Update_Mutation = {
  response: UserEmailNotificationsSettings_Update_Mutation$data;
  variables: UserEmailNotificationsSettings_Update_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmailNotificationSettings",
    "kind": "LinkedField",
    "name": "updateUserEmailNotificationSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmailNotificationSettingsOptions",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onExperimentDataProblem",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onExperimentStatusChange",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onSequentialExperimentMetricStatisticalSignificance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onTeamMetricStatisticalSignificance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onTeamFeatureFlagEnableDisable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onTeamFeatureFlagInProdAllocationUpdate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserEmailNotificationsSettings_Update_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserEmailNotificationsSettings_Update_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d836ecb7cb7ce8dc418eeead2544ad53",
    "id": null,
    "metadata": {},
    "name": "UserEmailNotificationsSettings_Update_Mutation",
    "operationKind": "mutation",
    "text": "mutation UserEmailNotificationsSettings_Update_Mutation(\n  $input: UpdateUserEmailNotificationSettingsInput!\n) {\n  updateUserEmailNotificationSettings(input: $input) {\n    id\n    enabled\n    settings {\n      onExperimentDataProblem\n      onExperimentStatusChange\n      onSequentialExperimentMetricStatisticalSignificance\n      onTeamMetricStatisticalSignificance\n      onTeamFeatureFlagEnableDisable\n      onTeamFeatureFlagInProdAllocationUpdate\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "22d4328f427f0e5b09b8291e4a2c2d4e";

export default node;
