import {
  CacheConfig,
  Environment,
  GraphQLResponse,
  Network,
  RecordSource,
  RequestParameters,
  Store,
  UploadableMap,
  Variables,
} from 'relay-runtime';

import {
  handleForbiddenResource,
  handleUnauthorizedResource,
  parseResponse,
} from 'helpers/client-environment-helper';

// Used by Next.js backend
export function minimalEnvironment(cookie: string) {
  async function minimalFetchQuery(
    request: RequestParameters,
    variables: Variables,
  ): Promise<GraphQLResponse> {
    // This uses a server-side environment variable, so this will not be available in the browser.
    const res = await fetch(`${process.env.API_BASE_URL}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Cookie: cookie,
      },
      body: JSON.stringify({
        query: request.text,
        variables,
      }),
    });

    return res.json();
  }

  return new Environment({
    network: Network.create(minimalFetchQuery),
    store: new Store(new RecordSource()),
  });
}

// Builds form data per the GraphQL multi-part upload spec
// Reference: https://github.com/jaydenseric/graphql-multipart-request-spec
function buildUploadablesFormData(operationJSON: string, uploadables: UploadableMap): FormData {
  if (!window.FormData) {
    throw new Error('Uploading files without `FormData` not supported.');
  }

  const formData = new FormData();
  formData.append('operations', operationJSON);
  const uploadableMap: {
    [key: string]: string[];
  } = {};

  Object.keys(uploadables).forEach((key) => {
    // handles multiple files scenario where object keys are files' indexes
    if (!isNaN(Number(key))) {
      uploadableMap[key] = [`variables.files.${key}`]; // it's not ideal but we have to make sure that for multi-part uploads we'll pass files as "files"
      return;
    }

    if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
      uploadableMap[key] = [`variables.${key}`];
    }
  });

  formData.append('map', JSON.stringify(uploadableMap));
  Object.keys(uploadableMap).forEach((key) => {
    formData.append(key, uploadables[key]);
  });

  return formData;
}

function fetchQuery(
  operation: { text: string | null | undefined },
  variables: unknown,
  cacheConfig: CacheConfig,
  uploadables: UploadableMap | null | undefined,
) {
  let body;
  let headers;
  const operationJSON = JSON.stringify({ query: operation.text, variables });
  if (uploadables) {
    body = buildUploadablesFormData(operationJSON, uploadables);
    headers = {
      'Apollo-Require-Preflight': 'true',
    };
  } else {
    headers = {
      'Content-Type': 'application/json',
    };
    body = operationJSON;
  }
  const response = fetch(process.env.NEXT_PUBLIC_GRAPHQL_URL ?? '', {
    method: 'POST',
    headers,
    body,
    credentials: 'include',
  });
  return parseResponse(response, { handleUnauthorizedResource, handleForbiddenResource });
}

let clientEnv: Environment | undefined;
export default function makeEnvironment() {
  if (!process.browser) {
    return new Environment({
      network: Network.create(fetchQuery),
      store: new Store(new RecordSource()),
    });
  }
  if (!clientEnv) {
    clientEnv = new Environment({
      network: Network.create(fetchQuery),
      store: new Store(new RecordSource()),
    });
  }

  return clientEnv;
}
