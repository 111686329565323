import { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { ICON_MAP, IconName } from './icon-map';
interface ISvgIcon {
  iconSource: string;
  iconColor: string;
  iconSize: number;
}
const SvgIcon = styled.div<ISvgIcon>(({
  iconSize: size,
  iconSource: source,
  iconColor: color
}) => ({
  flex: `0 0 ${size}px`,
  height: size,
  width: size,
  maxHeight: size,
  maxWidth: size,
  maskImage: `url("${source}")`,
  maskRepeat: 'no-repeat',
  maskSize: 'contain',
  backgroundColor: color
}));
interface IIcon extends Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className' | 'id'> {
  name: IconName;

  /** @default 'currentColor' */
  color?: string;
  /** @default 24 */
  size?: number;
}

/**
 * Component for rendering SVG icons from our [Figma icon library](https://www.figma.com/design/2fEScGXkSAxlZaLBCm4ki2/%F0%9F%92%A0-Official-Design-Library?node-id=3164-259970).
 * Icons are defined in {@link ICON_MAP}, and follow the naming convention of icons in Figma.
 *
 * By default, uses current text color as icon fill color.
 *
 * @example
 *
 * ```tsx
 * // Icon defined as "MD Icons/List" in Figma that inherits color from current text context and has the default size.
 * <Icon name="List" />
 *
 * // "MD Icons/Feature flag" icon in Figma of a "Colors/Text/Secondary" fill color and a set size of 16px.
 * <Icon name="Feature flag" color={colors.text.secondary} size={16} />
 * ```
 */
export default function Icon({
  name,
  color = 'currentColor',
  size = 24,
  ...props
}: IIcon) {
  return <SvgIcon iconSource={ICON_MAP[name]} iconColor={color} iconSize={size} {...props} />;
}