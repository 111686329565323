import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { COLOR_SUCCESS } from 'constants/colors';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { borderRadius, boxShadow, colors, fontSize, fontWeight, spacing } from 'theme';
import { FlexBox } from 'components/style-components/FlexBox';
import Icon from 'components/ui/icon/Icon';
import { IconName } from 'components/ui/icon/icon-map';
import { Box } from 'components/ui/layout/Box';
import Text from 'components/ui/typography/Text';
import withProps from 'components/ui/utils/withProps';
import { NavCollapsedContext } from 'hooks/useNavCollapsed';
import { zIndexTooltip } from '../../../styles/z-index';
import { sidebarExpandedWidth } from './styled';
const icons: Record<string, IconName> = {
  Admin: 'Tune.Vertical',
  Analysis: 'Experiment',
  Configuration: 'Feature flag',
  Definitions: 'Definitions',
  Docs: 'Help.Unfilled',
  'Getting Started': 'List',
  Insights: 'Idea',
  Metrics: 'Metric',
  Teams: 'Team',
  Warehouse: 'Database.Unfilled',
  Updates: 'Speaker'
};
const COLOR_BACKGROUND_SECONDARY_TRANSPARENT = 'rgba(242, 240, 237, 0.16)';

/**
 * Coverts a full path to a base route (e.g. "/feature-flags/1/variations" to "/feature-flags").
 * @param pathname - full path
 * @param depth - number of path segments to include for a 2-layer navigation
 */
const baseRoute = (pathname: string, depth = 1) => pathname.split('/').slice(0, 1 + depth).join('/') ?? '';
interface ISidebarOption {
  name: string;
  url: string;
  forceNewTab?: boolean;
  highlightStrategy?: string;
  subOptions?: ISidebarOption[];
}
const SidebarOption = ({
  name,
  url,
  forceNewTab: newTab,
  highlightStrategy,
  subOptions
}: ISidebarOption) => {
  const {
    collapsed
  } = useContext(NavCollapsedContext);
  const [isHovered, setIsHovered] = useState(false); // controls hover state for an option when the sidebar is collapsed
  const [subOptionsHidden, setSubOptionsHidden] = useState(true);
  const router = useRouter();
  const [selectedOption, setSelectedOption] = useState<string>('');
  useEffect(() => {
    const afterChange = (route: string) => {
      setSelectedOption(route);
    };
    router.events.on('routeChangeComplete', afterChange);
    return () => {
      router.events.off('routeChangeComplete', afterChange);
    };
  }, [router, setSelectedOption, subOptions, collapsed]);
  useEffect(() => {
    if (!collapsed) setIsHovered(false);
  }, [collapsed]);
  useEffect(() => {
    if (!collapsed) {
      return;
    }
    if (isHovered && !!subOptions) {
      setSubOptionsHidden(false);
      return;
    }
    setSubOptionsHidden(true);
  }, [collapsed, subOptions, isHovered]);
  const isOptionActive = useCallback((url: string, depth = 1): boolean => {
    if (!url) {
      return false;
    }
    if (selectedOption) {
      return url.toLowerCase().localeCompare(baseRoute(selectedOption, depth).toLowerCase()) === 0;
    }
    const selectedOptionName = baseRoute(router.pathname, depth);
    return url.toLowerCase().localeCompare(selectedOptionName.toLowerCase()) === 0;
  }, [router.pathname, selectedOption]);

  // ensure we're automatically expanding sub-options when a user lands on of them (and we're doing it once)
  const subOptionsAutoExpanded = useRef(false);
  useEffect(() => {
    if (collapsed || !subOptions) {
      return;
    }
    if (subOptionsAutoExpanded.current) {
      return;
    }
    for (const option of subOptions) {
      if (isOptionActive(option.url, 2)) {
        setSubOptionsHidden(false);
        subOptionsAutoExpanded.current = true;
        return;
      }
    }
  }, [collapsed, isOptionActive, subOptions]);

  // Temporarily, as part of dogfooding a multi-armed bandit, we have a few different ways to
  // "highlight" that there are new updates. Based on highlightStrategy, we will decorate the name
  // of this sidebar option.
  const emojiDecorator = highlightStrategy === 'emojiSparkle' ? '✨' : null;
  const backgroundColor = highlightStrategy === 'greenBackground' ? COLOR_SUCCESS : undefined;
  const color = highlightStrategy === 'greenText' ? COLOR_SUCCESS : undefined;
  const dot = highlightStrategy === 'greenDot' ? <span style={{
    color: COLOR_SUCCESS
  }}> ⬤</span> : null;
  const onOptionClick = () => {
    if (subOptions) {
      setSubOptionsHidden(h => !h);
      return;
    }
    setSelectedOption(url);
  };
  const shouldShowCaret = !!subOptions && !collapsed || !!subOptions && collapsed && isHovered;
  return <Box css={{
    height: collapsed ? '32px' : 'auto',
    position: 'relative'
  }}>
      <Wrapper collapsed={collapsed} isHovered={isHovered} onMouseEnter={() => collapsed && setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <StyledOption as={subOptions ? Box : withProps(Link, {
        href: url,
        target: newTab ? '_blank' : '_self'
      })} onClick={onOptionClick} color={color} backgroundColor={backgroundColor} collapsed={collapsed} isActive={isOptionActive(url, subOptionsHidden ? 1 : 2)} isParentHovered={isHovered}>
          {icons[name] && <Icon name={icons[name]} />}
          <Text color="inherit" bold={isOptionActive(url, subOptionsHidden ? 1 : 2)} ellipsis>
            {emojiDecorator}
            {name}
            {dot}
            {emojiDecorator}
          </Text>
          <Icon name="Caret.Down" css={{
          maxHeight: shouldShowCaret ? '50px' : '0px',
          transform: !subOptionsHidden ? 'rotate(180deg)' : 'none',
          transition: collapsed ? undefined : 'all 0.3s ease-in-out'
        }} />
        </StyledOption>

        <SubOptionsWrapper showOptions={!subOptionsHidden} disableTransition={collapsed}>
          <FlexBox flexDirection="column" css={{
          borderLeft: `1px solid ${colors.border.primary}`
        }}>
            {subOptions?.map(({
            url,
            name
          }) => <SideBarSubOption key={`[link-for]${name}:${url}`} href={url} isActive={isOptionActive(url, 2)} isParentHovered={isHovered}>
                {name}
              </SideBarSubOption>)}
          </FlexBox>
        </SubOptionsWrapper>
      </Wrapper>
    </Box>;
};
export default SidebarOption;
const Wrapper = styled.div<{
  collapsed?: boolean;
  isHovered?: boolean;
}>(({
  collapsed,
  isHovered
}) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: borderRadius.md,
  ':hover': !isHovered ? {
    width: `${sidebarExpandedWidth - 32}px`,
    zIndex: collapsed ? zIndexTooltip + 1 : undefined,
    boxShadow: collapsed ? boxShadow.default : undefined
  } : undefined
}), ({
  collapsed,
  isHovered = false
}) => isHovered && {
  position: 'absolute',
  width: `${sidebarExpandedWidth - 32}px`,
  zIndex: zIndexTooltip + 1,
  boxShadow: boxShadow.default,
  backgroundColor: collapsed ? colors.surface.white : 'rgba(242, 240, 237, 0.16)'
});
const StyledOption = styled('div', {
  shouldForwardProp: prop => !(prop.toString().startsWith('is') || ['as', 'backgroundColor', 'collapsed'].includes(prop.toString()))
})<{
  color?: string;
  backgroundColor?: string;
  collapsed?: boolean;
  isActive?: boolean;
  isParentHovered?: boolean;
}>(({
  color,
  backgroundColor,
  collapsed,
  isActive = false,
  isParentHovered = false
}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.sm,
  padding: spacing.xs,
  color: color ?? (isActive ? colors.text.primary : colors.text.white),
  cursor: 'pointer',
  fontWeight: isActive ? '500' : undefined,
  borderRadius: borderRadius.md,
  backgroundColor: backgroundColor ?? (isActive ? colors.background.secondary : undefined),
  ':hover': {
    ...(isActive ? {
      color: colors.text.primary
    } : {
      color: collapsed ? colors.text.primary : colors.text.white,
      backgroundColor: collapsed ? colors.surface.white : 'rgba(242, 240, 237, 0.16)'
    })
  },
  ...(isParentHovered && {
    color: colors.text.primary
  })
}));
const SubOptionsWrapper = styled.div<{
  showOptions?: boolean;
  disableTransition?: boolean;
}>(({
  showOptions = true,
  disableTransition = false
}) => ({
  padding: !showOptions ? 0 : `${spacing.xs} 0 ${spacing.xs} ${spacing.lg}`,
  maxHeight: !showOptions ? 0 : '500px',
  overflow: 'hidden',
  transition: disableTransition ? undefined : 'all 0.3s ease-in-out'
}));
const SideBarSubOption = styled(Link, {
  shouldForwardProp: prop => !prop.toString().startsWith('is')
})<{
  isActive?: boolean;
  isParentHovered?: boolean;
}>(({
  isActive = false,
  isParentHovered = false
}) => ({
  padding: `${spacing.xs} 0 ${spacing.xs} 20px`,
  color: isParentHovered ? colors.text.primary : colors.text.white,
  fontWeight: fontWeight.regular,
  fontSize: fontSize.body,
  '&:hover': isParentHovered ? {
    color: colors.text.primary,
    backgroundColor: colors.background.secondary
  } : {
    color: colors.text.white,
    backgroundColor: COLOR_BACKGROUND_SECONDARY_TRANSPARENT
  },
  ...(isActive && {
    color: colors.text.primary,
    fontWeight: fontWeight.medium,
    backgroundColor: colors.background.secondary
  })
}));