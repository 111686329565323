import { useEffect } from 'react';
import { EFeatureFlag } from 'constants/featureFlags';
import { SPACE_L, SPACE_XL } from 'constants/sizes';
import { PreloadedQuery, useFragment, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { graphql } from 'relay-runtime';
import { DividerLine } from 'components/divider/DividerLine';
import { useStringFlagOn } from 'components/feature-flag/hooks/useFlagAssignment';
import UserEmailNotificationsSettings from 'components/notifications/UserEmailNotificationsSettings';
import { FullScreenOverlay } from 'components/overlay/FullScreenOverlay';
import { ProfilePicture } from 'components/profile-picture/ProfilePicture';
import { Sidebar } from 'components/sidebar/Sidebar';
import { FlexBox } from 'components/style-components/FlexBox';
import { Suspense } from 'components/Suspense';
import IconButton from 'components/ui/buttons/IconButton';
import { Box } from 'components/ui/layout/Box';
import TeamsSimpleTable from 'components/user-profile-dropdown/UserProfileTeamsTable';
import { colors } from 'theme/colors';
import { FlexBoxItem } from '../style-components/FlexBoxItem';
import Heading from '../ui/typography/Heading';
import { ProfileEmail, ProfileName, ProfileRole } from './styled';
import { UserProfileSidebar_UserProfile_Fragment$key } from './__generated__/UserProfileSidebar_UserProfile_Fragment.graphql';
import { UserProfileSidebar_UserProfile_Query, UserProfileSidebar_UserProfile_Query$data } from './__generated__/UserProfileSidebar_UserProfile_Query.graphql';
const USER_PROFILE_FRAGMENT = graphql`
  fragment UserProfileSidebar_UserProfile_Fragment on User {
    name
    email
    imageUrl
    role
  }
`;
const USER_PROFILE_QUERY = graphql`
  query UserProfileSidebar_UserProfile_Query {
    userContext {
      teams {
        ...UserProfileTeamsTable_Fragment
      }
    }
    ...UserEmailNotificationsSettings_Fragment
  }
`;
interface IUserProfileSidebarContainer {
  userProfileFragment: UserProfileSidebar_UserProfile_Fragment$key;
  animate?: boolean;
  onClickOutside: () => void;
}
export default function UserProfileSidebarContainer({
  animate = true,
  onClickOutside,
  userProfileFragment
}: IUserProfileSidebarContainer): JSX.Element {
  const user = useFragment(USER_PROFILE_FRAGMENT, userProfileFragment);
  const [queryRef, loadQuery] = useQueryLoader<UserProfileSidebar_UserProfile_Query>(USER_PROFILE_QUERY);
  useEffect(() => {
    loadQuery({}, {
      fetchPolicy: 'store-and-network'
    });
  }, [loadQuery]);
  return <FullScreenOverlay>
      <Sidebar width={680} animate={animate} onClickOutside={onClickOutside}>
        <FlexBox flexDirection="column">
          <FlexBox flexDirection="row" padding={`${SPACE_L}px ${SPACE_XL}px `} margin={`0 0 ${SPACE_L}px 0`}>
            <ProfilePicture size="medium" imageUrl={user.imageUrl} text="" halfOpacity={false} />
            <FlexBox flexDirection="column" justifyContent="space-between" alignItems="flex-start" margin={`0 0 0 ${SPACE_L}px`}>
              <ProfileName>{user.name}</ProfileName>
              <ProfileEmail>{user.email}</ProfileEmail>
              <ProfileRole>{user.role}</ProfileRole>
            </FlexBox>
            <FlexBoxItem flexGrow={1} />
            <IconButton icon="X" onClick={onClickOutside} />
          </FlexBox>
          <DividerLine />
          <Suspense>
            {queryRef && <UserProfileSidebar queryRef={queryRef} userEmail={user.email} />}
          </Suspense>
        </FlexBox>
      </Sidebar>
    </FullScreenOverlay>;
}
interface IUserProfileSidebar {
  queryRef: PreloadedQuery<UserProfileSidebar_UserProfile_Query>;
  userEmail: string;
}
function UserProfileSidebar({
  queryRef,
  userEmail
}: IUserProfileSidebar) {
  const data: UserProfileSidebar_UserProfile_Query$data = usePreloadedQuery(USER_PROFILE_QUERY, queryRef);
  const emailNotificationsEnabled = useStringFlagOn(EFeatureFlag.EmailNotifications);
  return <FlexBox flexDirection="column" padding={`${SPACE_L}px ${SPACE_XL}px`} alignItems="flex-start" style={{
    color: colors.text.primary
  }}>
      <>
        <Heading variant="h3">My Teams</Heading>

        <Box margin={`${SPACE_L}px 0 ${SPACE_L}px 0`}>
          {data.userContext.teams.length === 0 && <Box width="100%" style={{
          color: colors.text.secondary
        }}>
              No teams assigned
            </Box>}
          {data.userContext.teams.length > 0 && <TeamsSimpleTable teamsRef={data.userContext.teams} />}
        </Box>
      </>
      {emailNotificationsEnabled && <>
          <Heading variant="h3">My Email Notifications</Heading>
          <Box margin={`${SPACE_L}px 0 ${SPACE_L}px 0`} style={{
        width: '100%'
      }}>
            <UserEmailNotificationsSettings email={userEmail} fragmentRef={data} />
          </Box>
        </>}
    </FlexBox>;
}