import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { graphql, useRelayEnvironment } from 'react-relay';
import { ConcreteRequest, createOperationDescriptor, fetchQuery, getRequest } from 'relay-runtime';
import { noop } from 'helpers/function-helper';
export const StoredDataContext = createContext<{
  isLoaded: boolean;
  reload: () => void;
}>({
  isLoaded: false,
  reload: noop
});

/**
 * Include only data that is widely re-used around the application and is unlikely to be changed during the application lifecycle.
 */
const QUERY = graphql`
  query StoredDataProvider_Query {
    users {
      id
      name
      imageUrl
      isDeleted
    }
  }
`;
interface IStoredDataProvider {
  children: JSX.Element;
}

/**
 * Important!!! This should be used only for pre-fetching data that is widely re-used around the application and is unlikely to be changed during the application lifecycle.
 * A primary example is a list of users with some basic properties.
 * Earlier all users where fetched on almost every page due to how fragments are assembled in a graphql query.
 * Now we fetch basic users list to the store, instruct it to not garbage-collect it, and tell consumer components to load it from the store.
 *
 * See an example of stored data usage in the `useStoredQueryLoader` hook comments.
 */
export function StoredDataProvider({
  children
}: IStoredDataProvider) {
  const [isLoaded, setIsLoaded] = useState(false);
  const env = useRelayEnvironment();
  const queryRequestRef = useRef<ConcreteRequest>();
  useEffect(() => {
    const queryRequest = getRequest(QUERY);
    const queryDescriptor = createOperationDescriptor(queryRequest, {});
    queryRequestRef.current = queryRequest;

    // Retain common data in cache so it's not get garbage-collected.
    env.retain(queryDescriptor);

    // This graphql request is non-blocking as it does not suspend UI rendering.
    // But components consumers of stored data should be suspended while `StoredDataContext.isLoaded` is `false`
    // The `useStoredQueryLoader` hook is responsible for it.
    fetchQuery(env, queryRequest, {}).subscribe({
      complete: () => {
        setIsLoaded(true);
      }
    });
  }, [env, setIsLoaded]);
  const reload = useCallback(() => {
    fetchQuery(env, (queryRequestRef.current as ConcreteRequest), {});
  }, [env]);
  return <StoredDataContext.Provider value={{
    isLoaded,
    reload
  }}>{children}</StoredDataContext.Provider>;
}