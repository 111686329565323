import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { createPortal } from 'react-dom';
import { PlacesType, Tooltip } from 'react-tooltip';
import { borderRadius, colors, fontSize, lineHeight, spacing } from 'theme';
import { zIndexTooltip } from 'styles/z-index';
const StyledTooltip = styled(Tooltip)({
  '&.react-tooltip': {
    zIndex: zIndexTooltip,
    maxWidth: '240px',
    padding: spacing.sm,
    fontSize: fontSize.bodySmall,
    lineHeight: lineHeight.text,
    color: colors.text.white,
    borderRadius: borderRadius.md,
    backgroundColor: colors.surface.primary,
    '--rt-opacity': 1,
    transition: 'opacity 250ms ease-out',
    '& > .react-tooltip-arrow': {
      zIndex: -1,
      height: 16,
      width: 16
    }
  }
});
export type TooltipType = 'default' | 'white';
export interface ITooltipWrapper {
  children: ReactNode;
  placement: PlacesType;
  text: string | ReactNode;
  id: string;
  disabled?: boolean;
  style?: object;
  tooltipStyle?: object;
  tooltipProps?: object;
  /** @deprecated use `text` parameter instead */
  customMessage?: ReactNode;
  delay?: number;
}

/** @deprecated Use `components/ui/tooltip/Tooltip` instead. */
export function TooltipWrapper({
  children,
  placement,
  text,
  id,
  disabled = false,
  style = {},
  tooltipStyle = {},
  tooltipProps = {},
  customMessage = null,
  delay = 300
}: ITooltipWrapper): JSX.Element {
  return <>
      <div id={id} data-tooltip-id={id} style={{
      width: 'fit-content',
      ...style
    }}>
        {children}
      </div>
      {!disabled && !!text && createPortal(<StyledTooltip id={id} place={placement} delayShow={delay} style={tooltipStyle} {...tooltipProps}>
            {text}
            {customMessage}
          </StyledTooltip>, document.body)}
    </>;
}