import { COLOR_GREY_300 } from 'constants/colors';

import styled from '@emotion/styled';
import { Property } from 'csstype';

export interface IFlexBoxDiv {
  alignItems?: Property.AlignItems;
  justifyContent?: Property.JustifyContent;
  flex?: Property.Flex;
  flexDirection?: Property.FlexDirection;
  flexWrap?: Property.FlexWrap;
  width?: Property.Width | number;
  height?: Property.Height | number;
  gap?: Property.Gap | number;
  rowGap?: Property.RowGap | number;
  margin?: Property.Margin | number;
  padding?: Property.Padding | number;
  backgroundColor?: Property.BackgroundColor;
}

export const FlexBoxDiv = styled.div(
  ({
    alignItems,
    justifyContent,
    flex,
    flexDirection,
    flexWrap,
    width = '100%',
    height,
    gap,
    margin,
    padding,
    backgroundColor,
  }: IFlexBoxDiv) => ({
    display: 'flex',
    alignItems,
    justifyContent,
    flex,
    flexDirection,
    flexWrap,
    width,
    height,
    minHeight: height,
    gap,
    margin,
    padding,
    backgroundColor,
  }),
);

export interface IWrapper {
  width?: Property.Width | number;
  height?: Property.Height | number;
  maxHeight?: Property.MaxHeight | number;
  maxWidth?: Property.MaxWidth | number;
  minHeight?: Property.MinHeight | number;
  minWidth?: Property.MinWidth | number;
  padding?: Property.Padding | number;
  margin?: Property.Margin | number;
  position?: Property.Position;
  display?: Property.Display;
  verticalAlign?: Property.VerticalAlign;
  overflow?: Property.Overflow;
  opacity?: Property.Opacity;
}

export const Wrapper = styled.div(
  ({
    width,
    height,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    padding,
    margin,
    position,
    display,
    verticalAlign,
    overflow,
    opacity,
  }: IWrapper) => ({
    width,
    height,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    padding,
    margin,
    position,
    display,
    verticalAlign,
    overflow,
    opacity,
  }),
);

export const AddEllipsisDiv = styled.div<{
  noOfLines: number;
  width: number | string;
  breakWord: boolean;
}>(({ noOfLines, width, breakWord }) => ({
  overflow: 'hidden',
  wordBreak: breakWord ? 'break-all' : 'normal',
  textOverflow: 'ellipsis',
  display: '-webkit-box !important',
  WebkitLineClamp: noOfLines,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
  maxWidth: width,
  width: '100%',
}));

export interface IStyledHr {
  margin?: Property.Margin;
  color?: Property.Color;
}
export const StyledHr = styled.div<IStyledHr>(({ margin, color = COLOR_GREY_300 }) => ({
  margin,
  borderBottom: `1px solid ${color}`,
  height: '1px',
}));
