import { SPACE_M } from 'constants/sizes';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { ISimpleTableColumn, SimpleTable } from 'components/table/SimpleTable';
import { Box } from 'components/ui/layout/Box';
import TeamIcon, { validIconName } from '../teams/TeamIcon';
import { TeamSimpleTableCell, TeamSimpleTableFirstCell, TeamSimpleTableHead } from './styled';
import { UserProfileTeamsTable_Fragment$data, UserProfileTeamsTable_Fragment$key } from './__generated__/UserProfileTeamsTable_Fragment.graphql';
const TEAMS_FRAGMENT = graphql`
  fragment UserProfileTeamsTable_Fragment on Team @relay(plural: true) {
    id
    icon
    name
    experimentCount
    metricCount
  }
`;
type TeamType = UserProfileTeamsTable_Fragment$data[number];
interface IUserProfileTeamsTable {
  teamsRef: UserProfileTeamsTable_Fragment$key;
}
export default function UserProfileTeamsTable({
  teamsRef
}: IUserProfileTeamsTable) {
  const teams = useFragment(TEAMS_FRAGMENT, teamsRef);
  const tableColumns: ISimpleTableColumn<TeamType>[] = [{
    name: 'Team',
    width: '242px',
    header: <TeamSimpleTableHead>Team</TeamSimpleTableHead>,
    renderCell: item => <TeamSimpleTableFirstCell>
          <Box margin={`0 ${SPACE_M}px 0 0`}>
            <TeamIcon iconName={(item.icon as validIconName)} />
          </Box>
          {item.name}
        </TeamSimpleTableFirstCell>
  }, {
    name: 'Experiments',
    width: '172px',
    header: <TeamSimpleTableHead>Experiments</TeamSimpleTableHead>,
    renderCell: item => <TeamSimpleTableCell>{item.experimentCount}</TeamSimpleTableCell>
  }, {
    name: 'Metrics',
    width: '186px',
    header: <TeamSimpleTableHead>Metrics</TeamSimpleTableHead>,
    renderCell: item => <TeamSimpleTableCell>{item.metricCount}</TeamSimpleTableCell>
  }];
  return <SimpleTable columns={tableColumns} data={[...teams]} />;
}