import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { COLOR_GREY_300, COLOR_WHITE } from 'constants/colors';
import { FONT_SIZE_REGULAR } from 'constants/typography';
import { offset as offsetMiddleware, shift as shiftMiddleware } from '@floating-ui/dom';
import { createPortal } from 'react-dom';
import { PlacesType, Tooltip } from 'react-tooltip';
import { boxShadow } from 'theme';
import ClickOutsideWrapper from 'components/click-outside-wrapper/ClickOutsideWrapper';
import { zIndexPopover } from 'styles/z-index';
import { colors } from 'theme/colors';
interface IPopoverWrapper {
  id: string;
  children: ReactNode;
  content: ReactNode;
  placement?: PlacesType;
  openOn?: 'hover' | 'click' | undefined; // With this prop underlying library itself will handle opening/closing the popup
  isOpen?: boolean | undefined; // This prop allows you to handle popover opening/closing state by parent component.
  // If you use this prop `isOpen` should be omitted and vice versa.
  disabled?: boolean;
  noArrow?: boolean;
  onClickOutside?: () => void;
  offset?: number;
  forcePlacement?: boolean;
  border?: boolean;
  assignPortalToBody?: boolean;
  width?: string;
  matchPopoverWidthWithWrapper?: boolean;
}
const defaultPopoverStyle: CSSProperties = {
  backgroundColor: COLOR_WHITE,
  color: colors.text.primary,
  boxShadow: boxShadow.default,
  borderRadius: 8,
  zIndex: zIndexPopover,
  padding: 0,
  fontSize: FONT_SIZE_REGULAR,
  overflow: 'hidden'
};
// this library expects border as its own prop, not as a part of style
const defaultBorder = `1px solid ${COLOR_GREY_300}`;
export function PopoverWrapper({
  children,
  content,
  isOpen = undefined,
  openOn = undefined,
  id,
  placement = 'top',
  disabled = false,
  noArrow = false,
  onClickOutside,
  offset = 10,
  forcePlacement = false,
  border = true,
  assignPortalToBody = false,
  width,
  matchPopoverWidthWithWrapper = false
}: IPopoverWrapper) {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };
    // Initial width set
    updateWidth();
    // Add resize event listener
    window.addEventListener('resize', updateWidth);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);
  if (!noArrow) {
    // arrow is not being shown if overflow is hidden
    defaultPopoverStyle.overflow = 'visible';
  }
  const renderTooltip = () => <Tooltip place={placement} id={id} style={{
    ...defaultPopoverStyle,
    ...(matchPopoverWidthWithWrapper ? {
      width: `${containerWidth}px`
    } : {})
  }} border={border ? defaultBorder : 'none'} opacity={1} openOnClick={openOn === 'hover' ? false : openOn === 'click' ? true : undefined} isOpen={isOpen} noArrow={noArrow} clickable closeOnScroll middlewares={[offsetMiddleware(Number(offset)), ...(forcePlacement ? [] : [shiftMiddleware()])]}>
      <div onClick={e => e.stopPropagation()}>{content}</div>
    </Tooltip>;
  const containerEl = document.getElementById(id);
  return <>
      <div ref={containerRef} id={id} data-tooltip-id={id} style={{
      width: width ?? 'fit-content'
    }} // should this be { width: auto; } instead?
    onClick={e => e.stopPropagation()}>
        {children}
      </div>
      {!disabled && createPortal(!onClickOutside ? renderTooltip() : <ClickOutsideWrapper onClickOutside={onClickOutside} captureEvent>
              {renderTooltip()}
            </ClickOutsideWrapper>, !assignPortalToBody && containerEl ? containerEl : document.body)}
    </>;
}