export function getAuthCookie() {
  const token = getCookie('auth');
  return token;
}

export function getUserIdCookie(): number | null {
  const token = getCookie('userId');
  const userId = parseInt(token, 10);
  return isNaN(userId) ? null : userId;
}

export function getCompanyIdCookie(): number | null {
  const token = getCookie('companyId');
  const companyId = parseInt(token, 10);
  return isNaN(companyId) ? null : companyId;
}

export function getCompanyNameCookie(): string | null {
  const name = getCookie('companyName');
  return !name ? null : name;
}

export function getCookie(cookieKey: string): string {
  if (!cookieKey) return '';
  const cookie = document.cookie;
  let token = '';
  (cookie || '')
    .split(';')
    .map((element) => element.trim())
    .forEach((element) => {
      const [key, value] = element.split('=');
      if (key == cookieKey) token = value;
    });
  return token;
}

export function resetCookie() {
  document.cookie = 'userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
