import { COLOR_GREY_100, COLOR_GREY_300, COLOR_GREY_600, COLOR_PRIMARY_300, COLOR_PRIMARY_600, COLOR_WHITE } from 'constants/colors';
import { FONT_SIZE_REGULAR } from 'constants/typography';
import { Global, css } from '@emotion/react';
import { fontSize } from 'theme';
import { colors } from 'theme/colors';
const globalStyles = css`
  html,
  body,
  #root {
    padding: 0;
    margin: 0;
    font-family: 'Cofo Sans', sans-serif;
    color: ${colors.text.primary};
  }

  #__next {
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .nav-link-no-decoration:hover {
    color: inherit;
    text-decoration: none;
  }

  b,
  strong {
    font-weight: 500;
  }

  * {
    box-sizing: border-box;
  }

  .custom-popover {
    max-width: 100%;
  }

  .custom-popover .popover {
    border-radius: 10px;
    border: 1px solid ${COLOR_GREY_300};
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
  }

  .no-margin-no-padding .popover-body {
    margin: 0;
    padding: 0;
  }

  .no-font-family-override .popover {
    font-family: inherit;
  }

  .custom-date-picker {
    width: 100% !important;
  }

  .react-datepicker {
    border: 1px solid ${COLOR_GREY_300} !important;
    box-sizing: border-box !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border-radius: 0 !important;
    background-color: ${COLOR_PRIMARY_600} !important;
  }

  .react-datepicker__header {
    background-color: ${COLOR_GREY_100} !important;
    border-bottom: none !important;
  }

  .react-datepicker__current-month {
    font-weight: normal !important;
    height: 30px !important;
  }

  .react-datepicker__day-names {
    background-color: ${COLOR_WHITE} !important;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 8px !important;
  }

  .react-datepicker__header--time--only {
    background-color: ${COLOR_WHITE} !important;
  }

  .react-datepicker__time-list-item--selected {
    background-color: ${COLOR_PRIMARY_600} !important;
  }

  /*
  * Custom sql highlighting style
  */
  .language-sql {
    color: ${colors.text.primary} !important;
  }

  .table > :not(:first-child) {
    border-top: 0;
  }

  /*
  * commandbar
  */
  .commandbar-launcher {
    display: none !important;
  }

  .global-dimension-css {
    padding: 0 !important;
  }

  .loader-with-dots svg {
    vertical-align: top;
  }

  .a-primary-color:hover {
    color: ${colors.text.link};
  }

  ::selection {
    background: ${COLOR_PRIMARY_300};
  }

  /* Styles for packages/frontend/components/date-range-picker/DateRangePicker.tsx */
  .dateRangePicker .rdrCalendarWrapper {
    font-size: ${fontSize.bodySmall};
    margin: 4px auto;
  }

  .dateRangePicker .rdrMonth {
    width: 22em;
  }

  .dateRangePicker .rdrDay {
    height: 3em;
  }

  /* range start is round */
  .dateRangePicker .rdrStartEdge,
  .dateRangePicker .rdrDayStartOfMonth .rdrEndEdge,
  .dateRangePicker .rdrDayEndOfMonth .rdrStartEdge,
  .dateRangePicker .rdrDayStartOfWeek .rdrStartEdge,
  .dateRangePicker .rdrDayEndOfWeek .rdrStartEdge,
  .dateRangePicker .rdrDayStartOfWeek .rdrEndEdge,
  .dateRangePicker .rdrDayEndOfWeek .rdrEndEdge,
  /* range end is round */
  .dateRangePicker .rdrEndEdge {
    background-color: ${colors.objectFill.secondary};
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    right: 0;
    left: 0;
  }

  /* range start and range end text color is white */
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: ${colors.text.white};
  }

  /* adds background for the right half of block containing circle (range start) */
  .rdrDays .rdrDay:has(.rdrStartEdge) {
    background: linear-gradient(
      to right,
      ${colors.surface.white} 50%,
      ${colors.background.purple} 50%
    );
    background-repeat: no-repeat;
    background-size: 100% 95%;
    background-position: 0px 1px;
  }

  /* adds background for the left half of block containing circle (range end) */
  .rdrDays .rdrDay:has(.rdrEndEdge) {
    background: linear-gradient(
      to left,
      ${colors.surface.white} 50%,
      ${colors.background.purple} 50%
    );
    background-repeat: no-repeat;
    background-size: 100% 95%;
    background-position: 0px 1px;
  }

  /* if range start and end is the same date, do not show half bgs */
  .rdrDays .rdrDay:has(.rdrStartEdge.rdrEndEdge),
  .rdrDays .rdrDay.rdrDayPassive {
    background: ${colors.surface.white};
  }

  /* sets range colors (without first and last days */
  .dateRangePicker .rdrInRange {
    background-color: ${colors.background.purple} !important;
    color: ${colors.text.primary};
  }

  .dateRangePicker .rdrDayStartOfWeek .rdrInRange,
  .dateRangePicker .rdrDayStartOfMonth .rdrInRange,
  .dateRangePicker .rdrDayEndOfWeek .rdrInRange,
  .dateRangePicker .rdrDayEndOfMonth .rdrInRange {
    border-radius: 0;
    right: 0;
    left: 0;
  }

  /* sets margins between days */
  .dateRangePicker .rdrSelected,
  .dateRangePicker .rdrInRange,
  .dateRangePicker .rdrStartEdge,
  .dateRangePicker .rdrEndEdge {
    top: 1px;
    bottom: 1px;
  }

  .dateRangePicker .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: ${colors.text.primary};
  }

  .dateRangePicker .rdrDayToday .rdrDayNumber span:after {
    background: ${colors.objectFill.secondary};
  }

  .dateRangePicker .rdrDefinedRangesWrapper {
    margin: 4px auto;
    font-size: ${fontSize.bodySmall};
    width: 148px;
  }

  .dateRangePicker .rdrStaticRange {
    border-bottom: 0;
    height: 44px;
  }

  .dateRangePicker .rdrStaticRange:hover {
    background-color: ${colors.hover.tertiary} !important;
  }

  .dateRangePicker .rdrStaticRange:hover .rdrStaticRangeLabel {
    background-color: inherit;
  }

  .dateRangePicker .rdrStaticRange.rdrStaticRangeSelected {
    color: ${colors.text.primary} !important;
    font-weight: 400;
    background-color: ${colors.background.purple};
  }

  .dateRangePicker .rdrMonthAndYearWrapper {
    height: 40px;
    padding-top: 10px;
  }

  .dateRangePicker .rdrMonthAndYearPickers select {
    padding: 10px 30px 10px 5px;
  }

  .dateRangePicker .rdrMonthAndYearPickers select:hover {
    background-color: transparent;
  }

  .dateRangePicker .rdrNextPrevButton {
    background-color: transparent;
  }

  .dateRangePicker .rdrNextPrevButton:hover {
    background-color: transparent;
  }

  .dateRangePicker .rdrPprevButton i {
    display: block;
    width: 8px;
    height: 8px;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid ${colors.objectFill.tertiary};
    border-left: 1px solid ${colors.objectFill.tertiary};
    transform: rotate(-45deg);
  }

  .dateRangePicker .rdrNextButton i {
    display: block;
    width: 8px;
    height: 8px;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid ${colors.objectFill.tertiary};
    border-left: 1px solid ${colors.objectFill.tertiary};
    transform: rotate(135deg);
  }

  .dateRangePicker .rdrMonthName {
    color: ${colors.text.primary};
  }

  /* Styles for packages/frontend/components/carousel/Carousel.tsx */

  .carousel-container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid ${COLOR_GREY_300};
  }

  .carousel-container .react-multiple-carousel__arrow {
    background-color: ${COLOR_WHITE};
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border: 1px solid ${COLOR_GREY_300};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }

  .carousel-container .react-multiple-carousel__arrow:hover {
    background-color: ${COLOR_WHITE};
  }

  .carousel-container .react-multiple-carousel__arrow::before {
    font-size: ${FONT_SIZE_REGULAR}px;
    color: ${colors.text.primary};
  }

  .carousel-container .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    padding-left: 2px;
  }

  .carousel-container .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    padding-left: 8px;
  }

  .carousel-container .dot-list {
    background-color: ${COLOR_WHITE};
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 12px;
    border-radius: 14px;
    border: 1px solid ${COLOR_GREY_300};
    height: 16px;
    display: flex;
    align-items: center;
    padding: 0 4px;
  }

  .carousel-container .dot-list .react-multi-carousel-dot {
    display: flex;
    justify-content: center;
    width: fit-content;
  }

  .carousel-container .dot-list .react-multi-carousel-dot button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    width: 8px;
    height: 8px;
    background-color: ${COLOR_GREY_300};
    margin-right: 2px;
    margin-left: 2px;
  }

  .carousel-container .dot-list .react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background-color: ${COLOR_GREY_600};
  }
`;
export default function GlobalStyle() {
  return <Global styles={globalStyles} />;
}