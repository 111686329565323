import { createContext } from 'react';

import { authorizeUserQuery } from './__generated__/authorizeUserQuery.graphql';

export type UserInfo = authorizeUserQuery['response']['userContext'];

export interface IUserContext extends UserInfo {
  reloadUserContext: () => void;
}

export const UserContext = createContext<IUserContext | null>(null);
