import { COLOR_WHITE } from 'constants/colors';

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { zIndexOffcanvas } from 'styles/z-index';

import { colors } from 'theme/colors';

const sidebarSlideIn = keyframes`
    from {
      transform: translateX(100%)
    }
    100% {
      transform: translateX(0)
    }
`;

export const SidebarContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  right: 0,
  height: '100vh',
  zIndex: zIndexOffcanvas,
  pointerEvents: 'none',
  color: colors.text.primary,
}));

export const SidebarDiv = styled.div<{ animate: boolean }>(({ animate }) => ({
  height: '100vh',
  width: '100%',
  background: COLOR_WHITE,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1), inset 1px 0px 0px #EAEAEA', // this is a color not in constants
  animation: animate ? `${sidebarSlideIn} 0.4s ease` : '',
  overflowY: 'auto',
  position: 'relative', // This is needed to allow absolutely positioned descendants to be scrolled.
}));
