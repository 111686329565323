// CustomToastContainer overrides DefaultToastContainer to use styles that comply with our design specs, e.g. Bootstrap's standard zIndexToast

import type { ToastContainerProps } from 'react-toast-notifications';
import { DefaultToastContainer } from 'react-toast-notifications';
import { zIndexToast } from 'styles/z-index';
export const CustomToastContainer = ({
  hasToasts,
  placement,
  ...props
}: ToastContainerProps) => {
  return <DefaultToastContainer {...{
    hasToasts,
    placement,
    style: {
      zIndex: zIndexToast
    }
  }} {...props} />;
};