import styled from '@emotion/styled';

import animatedLoading from './img/animated-loading.svg';

export const LoaderDiv = styled.div<{
  backgroundImage: string;
}>(({ backgroundImage }) => {
  return {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `cover`,
  };
});

export const GraphLoaderContainer = styled.div<{
  height: number | string;
}>(({ height }) => ({
  height: height,
}));

export const PageLoaderWrapper = styled.div<{
  height: number | string;
}>(({ height }) => ({
  height: height,
}));

export const PageLoaderDiv = styled.div<{
  backgroundImage: string;
}>(({ backgroundImage }) => {
  return {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `auto`,
    backgroundPosition: `center`,
  };
});

export const ExperimentProgressLoaderContainer = styled.div<{
  width: number;
}>(({ width }) => ({
  width,
}));

export const LoaderBackgroundDiv = styled.div<{
  backgroundImage: string;
}>(({ backgroundImage }) => {
  return {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `auto`,
    backgroundPosition: `center`,
  };
});

export const GradientLoaderBlock = styled.div<{
  height?: number | string;
  width?: number | string;
}>(({ height = 344, width = 1062 }) => ({
  width,
  height,
  borderRadius: 8,
  background: 'linear-gradient(90deg, #DADDE0 0%, #F6FBFF 100%)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  backgroundImage: `url(${animatedLoading})`,
}));
