import { COLOR_DARKENED_OVERLAY } from 'constants/colors';

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { zIndexOffcanvasBackdrop } from 'styles/z-index';

const fadeIn = keyframes`
    from {
      opacity: 0
    }
    100% {
      opacity: 1
    }
`;

export const FullScreenOverlayDiv = styled.div(() => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: COLOR_DARKENED_OVERLAY,
  animation: `${fadeIn} 0.4s ease`,
  zIndex: zIndexOffcanvasBackdrop,
}));
