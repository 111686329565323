import styled from '@emotion/styled';
import { colors } from 'theme/colors';
const KbdDiv = styled.div<{
  isDark?: boolean;
  size: number;
}>(({
  isDark = true,
  size
}) => ({
  minWidth: `${size}px`,
  width: `${size}px`,
  height: `${size}px`,
  padding: '0 2px',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  background: isDark ? colors.surface.tertiary : colors.surface.secondary,
  color: colors.text.white,
  fontSize: size - 6,
  lineHeight: `${size}px`,
  borderRadius: 4
}));
export interface IKbd {
  children: string;
  dark?: boolean;
  size?: number;
}
export default function Kbd({
  children,
  dark = false,
  size = 24
}: IKbd) {
  return <KbdDiv size={size} isDark={dark}>
      <div>{children}</div>
    </KbdDiv>;
}