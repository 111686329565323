import { graphql } from 'relay-runtime';

export const AUTH_QUERY = graphql`
  query authorizeUserQuery {
    userContext {
      userId
      email
      role
      currentCompany {
        companyId
        name
        databaseConnections {
          databaseType
        }
        config {
          hideDefinitionSqlRows
        }
      }
      permissions
      teams {
        id
      }
    }
  }
`;
