// Standard Bootstrap z-index values (https://getbootstrap.com/docs/5.2/layout/z-index/)
export const zIndexMembers = 5;

export const zIndexDropdown = 1000;
export const zIndexSticky = 1020;
export const zIndexFixed = 1030;
export const zIndexOffcanvasBackdrop = 1040;
export const zIndexOffcanvas = 1045;
export const zIndexModalBackdrop = 1050;
export const zIndexModal = 1055;
export const zIndexPopover = 1070;
export const zIndexTooltip = 1080;
export const zIndexToast = 1090;
export const zIndexLoader = 1100;
