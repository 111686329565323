export function checkObjectArraysEquility(
  a1: Record<string, string | number | boolean>[],
  a2: Record<string, string | number | boolean>[],
) {
  return a1.length === a2.length && a1.every((o, idx) => checkObjectsEquality(o, a2[idx]));
}

export function checkObjectsEquality(
  x: Record<string, string | number | boolean>,
  y: Record<string, string | number | boolean>,
) {
  let objectsAreSame = true;

  for (const propertyName in x) {
    // list of exceptions for comparison
    if (propertyName === 'isPrimary') {
      continue;
    }

    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValueByDotPath<ObjectType>(object: ObjectType, dotPath: string): any {
  const paths = dotPath.split('.');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return paths.reduce((val: any, subpath) => val?.[subpath], object);
}
