import { datadogRum } from '@datadog/browser-rum';
import Router from 'next/router';
import { GraphQLResponse } from 'relay-runtime';

import { resetCookie } from './cookie-helper';
import { isDevelopmentEnv } from './env-helper';

export type TFunctions = {
  handleUnauthorizedResource: () => void;
  handleForbiddenResource: () => void;
};

type TJsonError = {
  extensions?: {
    response?: {
      statusCode: number;
    };
    originalError?: {
      statusCode: number;
    };
  };
};

export function parseResponse(
  response: Promise<Response>,
  { handleUnauthorizedResource, handleForbiddenResource }: TFunctions,
): Promise<GraphQLResponse> {
  return response
    .then((response: Response) => {
      // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
      if (response.ok) {
        // todo: check the content type if it is json before parsing it.
        return response.json();
      }

      // HTTP 504 is returned by the GCP Cloud Run service when a request timed out
      // https://cloud.google.com/run/docs/configuring/request-timeout
      //
      // Convert to a GraphQL compatible error to ease handling in Relay.
      return {
        data: null,
        errors: [{ message: `NetworkError: ${response.status} ${response.statusText}` }],
      };
    })
    .then((responseJson) => {
      // HTTP 200
      //
      // Handle response from the API server's JwtGraphQLAuthGuard
      // https://docs.nestjs.com/guards
      //
      // This is an indication that the user is not permitted to access
      // the requested resource. We treat this as:
      // * not having a valid cookie
      // * not being associated with a resource at the request company
      //
      // In all of these cases we reset their cookie and redirect
      // them back to the `/` route where the login page will be rendered.
      //
      // {
      //   "statusCode": 403,
      //   "message": "Forbidden resource",
      //   "error": "Forbidden"
      // }
      if (
        Router.pathname !== '/' &&
        responseJson.errors?.length > 0 // &&
      ) {
        const errorCodes = responseJson.errors.flatMap((error: TJsonError) => [
          error.extensions?.response?.statusCode,
          error.extensions?.originalError?.statusCode,
        ]);

        if (errorCodes.some((code: number) => code === 401)) {
          return handleUnauthorizedResource();
        } else if (errorCodes.some((code: number) => code === 403)) {
          handleForbiddenResource();
          // we return responseJson, so that the calling component can handle
          // the error itself (render a toast, etc.)
          return responseJson;
        }
      }

      if (isDevelopmentEnv() && responseJson.errors?.length > 0) {
        console.error('Errors in GraphQL response', responseJson.errors);
      }

      return responseJson;
    });
}

export function handleUnauthorizedResource() {
  console.error('Unauthorized');
  window.location.replace('/');
  // prevents redirect loop by removing userId cookie
  resetCookie();
  // returning un-resolved promise to relay, so it won't
  // throw an error. Meanwhile we redirect to login page.
  return new Promise(() => null);
}

export function handleForbiddenResource() {
  console.error('Forbidden resource');
  datadogRum.addError(new Error('Forbidden resource'));
  // emit the toast here if possible
  // https://react-hot-toast.com/docs
  // toast.error('You do not have permission to perform that action');
}
