type Subtract<T, U> = Omit<T, keyof U>;

/**
 * Higher-Order Component (HOC) that injects specified props into a component,
 * removing the requirement for those props when using the wrapped component.
 * It's useful when you want to extend a component with styled components but
 * only with a specific set of values passed to it.
 *
 * @template T - The type of the component's props.
 * @template P - The type of the injected props.
 * @param {React.ComponentType<T>} Component - The component to wrap.
 * @param {P} props - The props to inject into the component.
 * @returns {React.FC<Subtract<T, P> & Partial<P>>} - The wrapped component with injected props.
 *
 * @example
 * // Let's say we want to create a custom H3 component and in order to do that
 * // we have to extend a generic <Heading/> component from 'ui/typography';
 * // Our custom component should be an h3 and nothing else,
 * // therefore we have to extend Heading but only an "h3" version of it:
 *
 * const CustomH3 = withProps(Heading, { variant: "h3" })({
 *   color: colors.text.secondary,
 *   fontSize: '32px',
 *   ...
 * });
 *
 * // And now we can use the new CustomH3 component directly without a need
 * // to specify a variant on every usage:
 * <CustomH3>Hello World</CustomH3>
 */
export default function withProps<T extends object, P extends Partial<T>>(Component: React.ComponentType<T>, props: P): React.FC<Subtract<T, P> & Partial<P>> {
  const WrappedComponent: React.FC<Subtract<T, P> & Partial<P>> = ownProps => <Component {...props} {...(ownProps as T)} />;
  WrappedComponent.displayName = Component.displayName;
  return WrappedComponent;
}