import { mapValues } from 'lodash';

export const EppoRoutes = {
  LOGOUT: '/logout',
};

export const EppoDomainRoutes = mapValues(
  EppoRoutes,
  (value) => `${process.env.NEXT_PUBLIC_API_BASE_URL}${value}`,
);
