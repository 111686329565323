import { COLOR_GREY_300, COLOR_PRIMARY, COLOR_WHITE } from 'constants/colors';

import styled from '@emotion/styled';
import { Property } from 'csstype';
import { borderRadius, fontSize, boxShadow, colors } from 'theme';

import { zIndexSticky } from 'styles/z-index';

import { palette } from 'theme/palette';

export const DataTableContainer = styled.div({
  position: 'relative',
  width: '100%',
  borderRadius: borderRadius.md,
  boxShadow: boxShadow.container,
});

export const TableEl = styled.table({
  width: '100%',
  maxWidth: '100%',
  backgroundColor: colors.surface.white,
  fontSize: fontSize.body,
  overflow: 'visible',
  height: '1px', // it will be ignored, required to set td/th heights. This way the right border will have correct height. Consult with Pavel
  borderRadius: borderRadius.md,
});

export const TableHead = styled.thead({});

export const TableHeadRow = styled.tr({
  borderBottom: `1px solid ${palette.neutral['300']}`,
});

export const TableHeadCell = styled.th<{
  sortingEnabled: boolean;
  width?: Property.Width | number;
  fixed?: boolean;
  rightOffset?: Property.Right;
  leftOffset?: Property.Left;
  color?: 'white' | 'grey';
  borderedLeft?: boolean;
  borderedRight?: boolean;
}>(
  {
    height: '100%',
    padding: '16px 0px',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: fontSize.body,

    '&:first-of-type': {
      borderTopLeftRadius: borderRadius.md,
    },
    '&:last-of-type': {
      borderTopRightRadius: borderRadius.md,
    },
  },
  ({
    sortingEnabled = false,
    width = 'auto',
    fixed = false,
    rightOffset = 'auto',
    leftOffset = 'auto',
    color = 'grey',
  }) => ({
    backgroundColor: color === 'grey' ? palette.neutral['200'] : palette.white,
    cursor: sortingEnabled ? 'pointer' : 'auto',
    minWidth: width,
    position: fixed ? 'sticky' : 'static',
    zIndex: fixed ? zIndexSticky - 1 : 'auto',
    left: leftOffset,
    right: rightOffset,
    '&:not(:first-of-type) > div': {
      height: '100%',
      borderLeft: fixed ? undefined : `1px solid ${palette.neutral['500']}`,
    },
  }),
  ({ borderedLeft = false }) =>
    borderedLeft && {
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        borderLeft: `1px solid ${palette.neutral['300']}`,
        boxShadow: '-1px 0px 4px 0px rgba(0, 0, 0, 0.12)',
      },
    },
  ({ borderedRight = false }) =>
    borderedRight && {
      '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        borderRight: `1px solid ${palette.neutral['300']}`,
        boxShadow: '1px 0px 4px 0px rgba(0, 0, 0, 0.12)',
      },
    },
);

// TODO replace all relevant tbodies w/ this style so each row can pull in this color
export const TableBody = styled.tbody({
  borderColor: colors.border.primary,
});

export const TableBodyRow = styled.tr<{ enableHovering: boolean }>(
  {
    '&:last-of-type': {
      '> td:first-of-type': {
        borderBottomLeftRadius: borderRadius.md,
      },
      '> td:last-of-type': {
        borderBottomRightRadius: borderRadius.md,
      },
    },
  },
  () => ({
    '&:not(:last-of-type) > td::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      borderBottom: `1px solid ${palette.neutral['300']}`,
    },
    '& > td': {
      backgroundColor: palette.white,
    },
  }),
  ({ enableHovering }) =>
    enableHovering
      ? {
          '&:hover > td': {
            backgroundColor: colors.hover.tertiary,
          },
        }
      : {},
);

export const TableBodyCell = styled.td<{
  fixed?: boolean;
  rightOffset?: Property.Right;
  leftOffset?: Property.Left;
  borderedLeft?: boolean;
  borderedRight?: boolean;
  rowDensity: 'wide' | 'medium' | 'slim';
}>(
  ({ fixed = false, leftOffset = 'auto', rightOffset = 'auto' }) => ({
    position: fixed ? 'sticky' : 'relative',
    left: leftOffset,
    right: rightOffset,
    zIndex: fixed ? zIndexSticky - 1 : 'auto',
  }),
  ({ rowDensity }) => {
    const paddingY = rowDensity === 'slim' ? 8 : rowDensity === 'medium' ? 12 : 16;
    return {
      padding: `${paddingY}px 16px`,
    };
  },
  ({ borderedLeft = false }) =>
    borderedLeft && {
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        borderLeft: `1px solid ${palette.neutral['300']}`,
        boxShadow: '-1px 0px 4px 0px rgba(0, 0, 0, 0.12)',
      },
    },
  ({ borderedRight = false }) =>
    borderedRight && {
      '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        borderRight: `1px solid ${palette.neutral['300']}`,
        boxShadow: '1px 0px 4px 0px rgba(0, 0, 0, 0.12)',
      },
    },
);

export const SimpleTableWrapper = styled.div<{ noBorder: boolean }>(({ noBorder }) => ({
  width: '100%',
  border: noBorder ? undefined : `1px solid ${COLOR_GREY_300}`,
  borderBottom: `1px solid ${COLOR_GREY_300}`,
  borderRadius: noBorder ? 0 : 8,
  overflow: 'hidden',
}));

export const SimpleTableEl = styled.table(() => ({
  width: '100%',
  backgroundColor: COLOR_WHITE,
  fontSize: '13px',
}));

export const SimpleTableHead = styled.thead(() => ({}));

export const SimpleTableHeadRow = styled.tr(() => ({
  borderBottom: `1px solid ${COLOR_GREY_300}`,
}));

export const SimpleTableHeadCell = styled.th<{
  width?: Property.Width;
  isHighlighted?: boolean;
  noBorder: boolean;
}>(({ width = 'auto', isHighlighted = false, noBorder }) => ({
  padding: `6px 16px 8px`,
  whiteSpace: 'nowrap',
  fontWeight: 500,
  width,
  borderTop: `2px solid ${isHighlighted ? COLOR_PRIMARY : 'transparent'}`,
  '&:first-of-type': {
    paddingLeft: noBorder ? '0px' : '16px',
  },
  '&:not(:first-of-type)': {
    borderLeft: noBorder ? undefined : `1px solid ${COLOR_GREY_300}`,
  },
}));

export const SimpleTableBody = styled.tbody(() => ({}));

export const SimpleTableBodyRow = styled.tr(() => ({
  '&:not(:last-of-type)': { borderBottom: `1px solid ${COLOR_GREY_300}` },
}));

export const SimpleTableBodyCell = styled.td<{ noBorder: boolean }>(({ noBorder }) => ({
  padding: '8px 16px',
  '&:first-of-type': {
    paddingLeft: noBorder ? '0px' : '16px',
  },
  '&:not(:first-of-type)': {
    borderLeft: noBorder ? undefined : `1px solid ${COLOR_GREY_300}`,
  },
}));

export const KeyValueTableContainer = styled.div<{ borderRounded: boolean }>(
  ({ borderRounded }) => ({
    width: '100%',
    border: `1px solid ${COLOR_GREY_300}`,
    borderRadius: borderRounded ? 6 : 0,
  }),
);

export const KeyValueTable = styled.table(() => ({
  width: '100%',
  borderCollapse: 'collapse',
}));

export const KeyValueTableTr = styled.tr(() => ({
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${COLOR_GREY_300}`,
  },
}));

export const KeyValueTableTdWithLabel = styled.td<{ width: number }>(({ width }) => ({
  padding: '12px 12px 12px 8px',
  textAlign: 'right',
  width,
  fontSize: 11.5,
  fontWeight: 500,
  lineHeight: '16px',
  textTransform: 'uppercase',
  color: colors.text.secondary,
}));

export const KeyValueTableTdWithValue = styled.td(() => ({
  borderLeft: `1px solid ${COLOR_GREY_300}`,
  padding: '12px 8px 12px 12px',
  textAlign: 'left',
}));
