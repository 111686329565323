import Alligator from './icons/Alligator.svg';
import Badger from './icons/Badger.svg';
import Bear from './icons/Bear.svg';
import Beaver from './icons/Beaver.svg';
import Beetle from './icons/Beetle.svg';
import Bird from './icons/Bird.svg';
import Bug from './icons/Bug.svg';
import Bumblebee from './icons/Bumblebee.svg';
import Bunny from './icons/Bunny.svg';
import Butterfly from './icons/Butterfly.svg';
import Camel from './icons/Camel.svg';
import Cat from './icons/Cat.svg';
import Chicken from './icons/Chicken.svg';
import Chinchilla from './icons/Chinchilla.svg';
import Cow from './icons/Cow.svg';
import Crab from './icons/Crab.svg';
import Deer from './icons/Deer.svg';
import Dinosaur from './icons/Dinosaur.svg';
import Dog from './icons/Dog.svg';
import Dolphin from './icons/Dolphin.svg';
import Dove from './icons/Dove.svg';
import Duck from './icons/Duck.svg';
import Elephant from './icons/Elephant.svg';
import Falcon from './icons/Falcon.svg';
import Fish from './icons/Fish.svg';
import Fox from './icons/Fox.svg';
import Frog from './icons/Frog.svg';
import Global from './icons/Global.svg';
import Gorilla from './icons/Gorilla.svg';
import Hamster from './icons/Hamster.svg';
import Hawk from './icons/Hawk.svg';
import Hedgehog from './icons/Hedgehog.svg';
import Hippo from './icons/Hippo.svg';
import Horse from './icons/Horse.svg';
import Hummingbird from './icons/Hummingbird.svg';
import Jellyfish from './icons/Jellyfish.svg';
import Kangaroo from './icons/Kangaroo.svg';
import Kiwi from './icons/Kiwi.svg';
import Lion from './icons/Lion.svg';
import Llama from './icons/Llama.svg';
import Manatee from './icons/Manatee.svg';
import Nautilus from './icons/Nautilus.svg';
import Octopus from './icons/Octopus.svg';
import Owl from './icons/Owl.svg';
import Parrot from './icons/Parrot.svg';
import Peacock from './icons/Peacock.svg';
import Pelican from './icons/Pelican.svg';
import Penguin from './icons/Penguin.svg';
import Pig from './icons/Pig.svg';
import Poodle from './icons/Poodle.svg';
import Prawn from './icons/Prawn.svg';
import Quail from './icons/Quail.svg';
import Rhino from './icons/Rhino.svg';
import Seagull from './icons/Seagull.svg';
import Seahorse from './icons/Seahorse.svg';
import Seal from './icons/Seal.svg';
import Shark from './icons/Shark.svg';
import Shell from './icons/Shell.svg';
import Shibainu from './icons/Shibainu.svg';
import Sloth from './icons/Sloth.svg';
import Snail from './icons/Snail.svg';
import Squirrel from './icons/Squirrel.svg';
import Starfish from './icons/Starfish.svg';
import Tapir from './icons/Tapir.svg';
import Terrier from './icons/Terrier.svg';
import Toucan from './icons/Toucan.svg';
import Turtle from './icons/Turtle.svg';
import Unicorn from './icons/Unicorn.svg';
import Whale from './icons/Whale.svg';
import { TeamIconBackground, TeamIconImg } from './styled';
export const animalIcons = ({
  Alligator,
  Badger,
  Bear,
  Beaver,
  Beetle,
  Bird,
  Bug,
  Bumblebee,
  Bunny,
  Butterfly,
  Camel,
  Cat,
  Chicken,
  Chinchilla,
  Cow,
  Crab,
  Deer,
  Dinosaur,
  Dog,
  Dolphin,
  Dove,
  Duck,
  Elephant,
  Falcon,
  Fish,
  Fox,
  Frog,
  Gorilla,
  Hamster,
  Hawk,
  Hedgehog,
  Hippo,
  Horse,
  Hummingbird,
  Jellyfish,
  Kangaroo,
  Kiwi,
  Lion,
  Llama,
  Manatee,
  Nautilus,
  Octopus,
  Owl,
  Parrot,
  Peacock,
  Pelican,
  Penguin,
  Pig,
  Poodle,
  Prawn,
  Quail,
  Rhino,
  Seagull,
  Seahorse,
  Seal,
  Shark,
  Shell,
  Shibainu,
  Sloth,
  Snail,
  Squirrel,
  Starfish,
  Tapir,
  Terrier,
  Toucan,
  Turtle,
  Unicorn,
  Whale
} as const);
export type validIconName = keyof typeof animalIcons;
interface ITeamIcon {
  iconName: validIconName | 'Global';
  size?: string;
}
export default function TeamIcon({
  iconName,
  size
}: ITeamIcon) {
  if (iconName === 'Global') {
    return <TeamIconBackground size={size}>
        <TeamIconImg src={Global} size={size} />
      </TeamIconBackground>;
  }
  if (!animalIcons[iconName]) {
    console.error('icon name not in list of icons');
    return <TeamIconImg src={Whale} />;
  }
  return <TeamIconBackground size={size}>
      <TeamIconImg src={animalIcons[iconName]} size={size} />
    </TeamIconBackground>;
}