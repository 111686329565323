import { ReactNode } from 'react';
import { Property } from 'csstype';
import startCase from 'lodash/startCase';
import { SimpleTableBody, SimpleTableBodyCell, SimpleTableBodyRow, SimpleTableWrapper, SimpleTableEl, SimpleTableHead, SimpleTableHeadCell, SimpleTableHeadRow } from 'components/table/styled';
import { Box } from 'components/ui/layout/Box';
import { getValueByDotPath } from 'helpers/objects';
type IdType = string | number;
export interface ISimpleTableBaseDataType {
  id: IdType | Readonly<IdType>;
}
export interface ISimpleTableColumn<DataType extends ISimpleTableBaseDataType> {
  name: string; // Required and unique column name. May be a dotted path of the `data` property.
  header?: string | ReactNode; // If omitted the `name` in a start case will be used
  renderCell?: (item: DataType) => string | ReactNode; // If omitted the component will try to render the `name` dotted path property of `data` object
  width?: Property.Width; // Gets the `auto` value if omitted
  isHighlighted?: boolean;
}
export interface ISimpleTable<DataType extends ISimpleTableBaseDataType> {
  data: DataType[];
  columns: ISimpleTableColumn<DataType>[];
  cyElementType?: string;
  noBorder?: boolean;
}

// Note! The rendered table will take 100% of its container width.
export function SimpleTable<DataType extends ISimpleTableBaseDataType>({
  data,
  columns,
  cyElementType,
  noBorder = false
}: ISimpleTable<DataType>) {
  return <SimpleTableWrapper noBorder={noBorder}>
      <SimpleTableEl data-cy-element-type={cyElementType}>
        <SimpleTableHead>
          <SimpleTableHeadRow>
            {columns.map(({
            name,
            header = null,
            isHighlighted = false,
            width
          }) => <SimpleTableHeadCell key={`table-head-cell-${name}`} isHighlighted={isHighlighted} noBorder={noBorder} width={width}>
                <Box>{header !== null ? header : startCase(name)}</Box>
              </SimpleTableHeadCell>)}
          </SimpleTableHeadRow>
        </SimpleTableHead>

        <SimpleTableBody>
          {data.map((item, index) => <SimpleTableBodyRow key={`table-body-row-${item.id || index}`}>
              {columns.map(({
            name,
            renderCell = null
          }) => <SimpleTableBodyCell key={`table-body-cell-${name}`} noBorder={noBorder}>
                  {renderCell ? renderCell(item) : getValueByDotPath(item, name)}
                </SimpleTableBodyCell>)}
            </SimpleTableBodyRow>)}
        </SimpleTableBody>
      </SimpleTableEl>
    </SimpleTableWrapper>;
}