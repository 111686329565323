import axios, { AxiosInstance } from 'axios';

/**
 * Basic api caller service. Uses axios.
 */
export class ApiCallerService {
  private apiCaller: AxiosInstance;

  /**
   * Creates an instance of axios with correct baseUrl and headers.
   */
  constructor() {
    this.apiCaller = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
      timeout: 5 * 60 * 1000, // set to 5min to match backend and GCP
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Credentials: 'include',
      },
    });
  }

  /**
   * @param {string} url
   * @param {object} config
   *
   * @returns {AxiosPromise}
   */
  get(url = '', config = {}) {
    this.updateAuthorizationHeaderFromCookie();
    return this.apiCaller.get(url, config);
  }

  /**
   * @param {string} url
   * @param {object} data
   *
   * @returns {AxiosPromise}
   */
  post(url = '', data = {}) {
    this.updateAuthorizationHeaderFromCookie();
    return this.apiCaller.post(url, data);
  }

  /**
   * @param {string} url
   * @param {object} data
   *
   * @returns {AxiosPromise}
   */
  patch(url = '', data = {}) {
    this.updateAuthorizationHeaderFromCookie();
    return this.apiCaller.patch(url, data);
  }

  /**
   * @param {string} url
   * @param {object} data
   *
   * @returns {AxiosPromise}
   */
  put(url = '', data = {}) {
    this.updateAuthorizationHeaderFromCookie();
    return this.apiCaller.put(url, data);
  }

  /**
   * @param {string} url
   * @param {object} config
   *
   * @returns {AxiosPromise}
   */
  delete(url = '', config = {}) {
    this.updateAuthorizationHeaderFromCookie();
    return this.apiCaller.delete(url, config);
  }

  updateAuthorizationHeaderFromCookie() {
    const cookie = document.cookie;
    let token = '';
    (cookie || '')
      .split(';')
      .map((element) => element.trim())
      .forEach((element) => {
        const [key, value] = element.split('=');
        if (key == 'auth') token = value;
      });
    this.setAuthorizationHeader(token);
  }

  /**
   * @param {string} token
   */
  setAuthorizationHeader(token = '') {
    this.setHeader('Authorization', `Bearer ${token}`);
  }

  /**
   * @param {String} locale
   */
  setAcceptLanguageHeader(locale = 'en') {
    this.setHeader('Accept-Language', locale);
  }

  /**
   * @param {string} name
   * @param {string} value
   */
  setHeader(name = '', value = '') {
    this.apiCaller.defaults.headers.common[name] = value;
  }
}

export interface IErrorResult {
  statusCode: number;
  message: string;
}

export default new ApiCallerService();
