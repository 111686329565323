import { CSSProperties } from 'react';
import { TRANSFORM_BLACK_TO_WHITE } from 'constants/colors';
import eppoLogo from './eppo-logo-full.svg';

// TODO replace constants with global generic values
const TRANSFORM_BLACK_TO_TEXT_PRIMARY = 'invert(8%) sepia(5%) saturate(973%) hue-rotate(349deg) brightness(97%) contrast(85%)';
export interface ILogo {
  color?: 'light' | 'dark';
  /** Width is automatically adjusted to keep the original aspect ratio if the height is specified. */
  height?: CSSProperties['height'];
}
export default function EppoLogo({
  color = 'dark',
  height
}: ILogo) {
  return <img src={eppoLogo} style={{
    aspectRatio: '93 / 29',
    height,
    filter: color === 'light' ? TRANSFORM_BLACK_TO_WHITE : TRANSFORM_BLACK_TO_TEXT_PRIMARY
  }} />;
}