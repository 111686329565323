import { functionalColors } from './colors';
import { palette } from './palette';

export const boxShadow = {
  success: `0px 0px 0px 2px ${functionalColors.success[300]}`,
  error: `0px 0px 0px 2px ${functionalColors.error[300]}`,
  neutral: `0px 0px 0px 2px ${palette.neutral[200]}`,
  hover: '1px 1px 4px 0px rgba(0, 0, 0, 0.16) inset',
  active: `0px 0px 0px 2px ${palette.peppo[300]}`,
  activeLarge: `0px 0px 0px 3px ${palette.peppo[300]}`,
  default: '0 0 8px rgba(0, 0, 0, 0.12)',
  container: '0px 0px 6px 0px rgba(0, 0, 0, 0.08)',
  focusRing: '0px 0px 0px 2px rgba(108, 85, 212, 0.70)',
};
