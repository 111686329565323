import { ChangeEvent, useRef } from 'react';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { CheckboxInput as StyledInput, CheckboxInputWrapper, CheckboxVisual } from 'components/inputs/styled';
export interface IFormCheckboxInput<TFormValues extends FieldValues> {
  isDisabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  cyElementType?: string;
  value: boolean; // TODO: consider refactoring, we can get the value with useWatch
  // React-hook-form related props
  name: Path<TFormValues>;
  register: UseFormRegister<TFormValues>;
}

/**
 * @deprecated use FormCheckboxInputV2 instead
 */
export default function FormCheckboxInput<TFormValues extends FieldValues>({
  isDisabled = false,
  onChange,
  cyElementType = '',
  value,
  name,
  register
}: IFormCheckboxInput<TFormValues>): JSX.Element {
  const {
    onChange: onChangeField,
    ref,
    ...field
  } = register(name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onClick = () => {
    inputRef.current?.click();
  };
  return <CheckboxInputWrapper>
      <StyledInput {...field} ref={e => {
      ref(e);
      inputRef.current = e;
    }} disabled={isDisabled} type="checkbox" onChange={async e => {
      await onChangeField(e);
      onChange && onChange(e);
    }} />
      <CheckboxVisual isDisabled={isDisabled} isChecked={value} onClick={onClick} data-cy-element-type={cyElementType} />
    </CheckboxInputWrapper>;
}