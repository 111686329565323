import { ProfileImageSize, ProfilePictureDefault, ProfilePictureDiv, ProfilePictureImg } from './styled';
interface IProfilePicture {
  imageUrl: string | null;
  text: string;
  halfOpacity?: boolean;
  size: ProfileImageSize;
  zIndex?: number;
}
function getFirstLetter(text: string) {
  const matches = text.match(/\b(\w)/g);
  return matches ? matches[0] : '';
}
export function ProfilePicture({
  imageUrl,
  text,
  halfOpacity = false,
  size,
  zIndex
}: IProfilePicture) {
  const firstLetter = getFirstLetter(text);
  return <ProfilePictureDiv size={size} zIndex={zIndex}>
      {imageUrl ? <ProfilePictureImg size={size} src={imageUrl} halfOpacity={halfOpacity} /> : <ProfilePictureDefault firstLetter={firstLetter} size={size} halfOpacity={halfOpacity}>
          {firstLetter}
        </ProfilePictureDefault>}
    </ProfilePictureDiv>;
}