import { MouseEventHandler, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import { fontSize, fontWeight, lineHeight } from 'theme';
import { colors } from 'theme/colors';
type HeadingVariant = 'h1' | 'h2' | 'h3';
interface IHeadingBase {
  variant: HeadingVariant;
  ellipsis?: boolean;

  // overrides
  color?: string;
  margin?: Property.Margin;
  lineHeight?: string;
  whiteSpace?: Property.WhiteSpace;
}
const HeadingBase = styled.p<IHeadingBase>({
  fontFamily: 'Cofo Sans, sans-serif',
  lineHeight: lineHeight.heading
}, ({
  variant
}) => ({
  h1: {
    fontSize: fontSize.h1,
    fontWeight: fontWeight.regular
  },
  h2: {
    fontSize: fontSize.h2,
    fontWeight: fontWeight.regular
  },
  h3: {
    fontSize: fontSize.h3,
    fontWeight: fontWeight.medium
  }
})[variant],
// Overrides
({
  color = colors.text.primary,
  lineHeight,
  whiteSpace = 'normal',
  margin = '0'
}) => ({
  color,
  margin,
  lineHeight,
  whiteSpace
}), ({
  ellipsis = false
}) => ellipsis && {
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  textOverflow: 'ellipsis'
});
export interface IHeading extends IHeadingBase {
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLHeadingElement>;
  'data-cy-element-type'?: string;
  'data-cy-element-id'?: string;
  'data-cy-link'?: string;
}
export default function Heading({
  children,
  variant,
  ...props
}: IHeading) {
  return <HeadingBase as={variant} variant={variant} {...props}>
      {children}
    </HeadingBase>;
}