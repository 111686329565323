import { CSSProperties, useMemo, useRef } from 'react';
import { PlacesType } from 'react-tooltip';
import { TooltipWrapper } from 'components/tool-tip/TooltipWrapper';
import { domSafeElementId } from 'helpers/dom-helper';
import { AddEllipsisDiv } from './styled';
export interface IAddEllipsis {
  width?: number | string;
  text: string;
  noOfLines: number;
  breakWord?: boolean;
  customDelimiter?: string;
  showTooltip?: boolean;
  cyElementType?: string;
  tooltipPlacement?: PlacesType;
  style?: CSSProperties;
}
export function AddEllipsis({
  width = '100%',
  text,
  noOfLines,
  breakWord = false,
  customDelimiter = undefined,
  showTooltip = true,
  tooltipPlacement = 'top',
  cyElementType = '',
  style = {}
}: IAddEllipsis): JSX.Element {
  const elementId = useMemo(() => domSafeElementId(`ellipsis-tooltip-${Math.floor(Math.random() * 100000)}`), [text]);
  const divRef = useRef<HTMLDivElement>(null);
  const isEllipsisUsed = !divRef.current ? false : divRef.current.offsetHeight < divRef.current.scrollHeight || divRef.current.offsetWidth < divRef.current.scrollWidth;
  return <TooltipWrapper id={elementId} placement={tooltipPlacement} text={text} disabled={!showTooltip || !isEllipsisUsed}>
      <AddEllipsisDiv ref={divRef} width={width} noOfLines={noOfLines} breakWord={breakWord} style={style} data-cy-element-type={cyElementType} dangerouslySetInnerHTML={{
      __html: customDelimiter ? text?.replaceAll(customDelimiter, `${customDelimiter}<wbr>`) : text
    }} />
    </TooltipWrapper>;
}