import { useCallback } from 'react';

export const useLocalstorage = () => {
  const setItem = useCallback((key: string, value: string) => {
    if (window.localStorage) {
      window.localStorage.setItem(key, value);
    }
  }, []);

  const getItem = useCallback((key: string) => {
    if (window.localStorage) {
      return window.localStorage.getItem(key);
    }
  }, []);

  return { setItem, getItem };
};
