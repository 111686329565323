import { useCallback } from 'react';

import { AppearanceTypes, useToasts, Options } from 'react-toast-notifications';

const AUTO_DISMISS_TIMEOUT = '3000';
const IS_AUTO_DISMISS_ENABLED = true;

type UseCustomToastReturnType = {
  addToast: (
    message: React.ReactNode,
    appearance: AppearanceTypes,
    options?: Omit<Options, 'appearance'>,
  ) => void;
};

const DEFAULT_OPTIONS: Options = {
  autoDismiss: IS_AUTO_DISMISS_ENABLED,
  autoDismissTimeout: AUTO_DISMISS_TIMEOUT,
};

export function useCustomToasts(): UseCustomToastReturnType {
  const { addToast } = useToasts();

  const customAddToast = useCallback(
    (message: React.ReactNode, appearance: AppearanceTypes, options: Options = {}) => {
      const newOptions = { ...DEFAULT_OPTIONS, ...options, appearance };
      addToast(message, newOptions);
    },
    [addToast],
  );

  return {
    addToast: customAddToast,
  };
}
