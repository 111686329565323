import { EFeatureFlag } from 'constants/featureFlags';

import { getInstance } from '@eppo/js-client-sdk';

import augmentSubjectAttributes from 'lib/augment-subject-attributes';

import { useUser } from 'hooks/useUser';

/**
 * This method is meant to pull non-randomized feature flag configurations.
 * It randomizes based on userId, so if a flag has randomization, different users within the same company may get different results.
 */
export function useBoolFlagAssignment(
  flagKey: EFeatureFlag,
  subjectAttributes = {} as Record<string, string | number>,
  defaultValue = false,
): boolean {
  const userId = augmentSubjectAttributes(subjectAttributes, useUser());
  const subjectKey = String(userId);

  const eppoClient = getInstance();
  return eppoClient.getBooleanAssignment(flagKey, subjectKey, subjectAttributes, defaultValue);
}

/**
 * This method is meant to pull non-randomized feature flag configurations.
 * It randomizes based on userId, so if a flag has randomization, different users within the same company may get different results.
 */
export function useStringFlagAssignment(
  flagKey: EFeatureFlag,
  subjectAttributes: Record<string, string | number>,
  defaultValue: string,
): string {
  const userId = augmentSubjectAttributes(subjectAttributes, useUser());
  const subjectKey = String(userId);

  const eppoClient = getInstance();
  return eppoClient.getStringAssignment(flagKey, subjectKey, subjectAttributes, defaultValue);
}

/**
 * Shortcut for checking legacy "ON/OFF" feature flags (string-valued flags used as boolean switches).
 *
 * @deprecated Use useBoolFlagAssignment instead, together with a boolean-typed flag.
 * This method is only to be used to support legacy string-valued flags.
 */
export function useStringFlagOn(
  flagKey: EFeatureFlag,
  subjectAttributes: Record<string, string | number> = {},
): boolean {
  const assignment = useStringFlagAssignment(flagKey, subjectAttributes, 'off');
  return ['on', 'true'].includes(assignment.toString().toLowerCase());
}
