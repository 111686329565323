import { COLOR_WHITE } from 'constants/colors';

import styled from '@emotion/styled';

import { colors } from 'theme/colors';

import BGImage from './img/login.jpg';

export const LoginPageLayout = styled.div(() => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: `url(${BGImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  padding: 32,
  '@media (max-width: 500px)': {
    background: 'none',
  },
}));

export const LoginPageWrapper = styled.div(() => ({
  display: 'flex',
  width: '100%',
  height: 'calc(100% - 32px)',
}));

export const LogoWrapper = styled.div(() => ({
  display: 'flex',
  height: 32,
  width: '100%',
}));

export const LoginPageColumn = styled.div<{ hiddenOnMobile?: boolean }>(
  ({ hiddenOnMobile = false }) => ({
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 999px)': {
      display: hiddenOnMobile ? 'none' : undefined,
      width: !hiddenOnMobile ? '100%' : undefined,
    },
  }),
);

export const LoginPageFormWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  width: 368,
  height: 270,
  padding: 16,
  borderRadius: 8,
  border: `1px solid ${colors.border.primary}`,
  backgroundColor: COLOR_WHITE,
}));

export const LoginPageForm = styled.div(() => ({
  height: '60%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
}));

// TODO migrate to styled(Text) as soon as new fonts feature flag is removed
export const LoginPageFormContainerP = styled.p(() => ({
  fontSize: 13,
  color: colors.text.secondary,
}));

export const LoginPageHref = styled.a(() => ({
  color: colors.text.link,
}));
