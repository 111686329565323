import apiCallerService from '../services/ApiCallerService';

export async function callGraphQLApiDirectly<K, V>(
  graphqlString: string,
  variables: V,
): Promise<K> {
  return (
    await apiCallerService.post(process.env.NEXT_PUBLIC_GRAPHQL_URL, {
      query: graphqlString,
      variables,
    })
  )?.data?.data as K;
}
