import { Children, ReactElement, ReactNode, cloneElement, isValidElement, useId } from 'react';
import styled from '@emotion/styled';
import { createPortal } from 'react-dom';
import { PlacesType, Tooltip as ReactTooltip, ITooltip as IReactTooltip } from 'react-tooltip';
import { borderRadius, colors, fontSize, lineHeight, spacing } from 'theme';
import { zIndexTooltip } from 'styles/z-index';
const TooltipBase = styled(ReactTooltip)({
  '&.react-tooltip': {
    zIndex: zIndexTooltip,
    maxWidth: '240px',
    padding: spacing.sm,
    fontSize: fontSize.bodySmall,
    lineHeight: lineHeight.text,
    color: colors.text.white,
    borderRadius: borderRadius.md,
    backgroundColor: colors.surface.primary,
    '--rt-opacity': 1,
    transition: 'opacity 250ms ease-out',
    '& > .react-tooltip-arrow': {
      zIndex: -1,
      height: 16,
      width: 16
    }
  }
});
export interface ITooltip {
  /**
   * Tooltip content.
   */
  text: string | ReactNode;
  disabled?: boolean;
  placement?: PlacesType;
  delayShow?: number;
  /**
   * Whether to attach the tooltip directly to the child. Useful in cases when you don't want the wrapping div to mess up some styles.
   *
   * If `true`, will render only the first child provided.
   */
  triggerAsChild?: boolean;
  /**
   * Non-mandatory prop to use a specific id with the tooltip.
   */
  id?: string;
  tooltipProps?: IReactTooltip;
  children: ReactNode;
}

/**
 * Primitive UI component for displaying a tooltip.
 *
 * NOTE: This component does not require a mandatory `id` prop.
 *
 * @example
 *
 * ```tsx
 * // Default tooltip.
 * <Tooltip text="Edit">
 *   <IconButton icon="Edit" onClick={handleEdit} />
 * </Tooltip>
 *
 * // Tooltip with non plain text content.
 * <Tooltip text={<>Edit the <b>feature flag</b></>}>
 *   <IconButton icon="Edit" onClick={handleEdit} />
 * </Tooltip>
 *
 * // Tooltip that attaches itself directly to the child so the styles are not messed up.
 * <SplitButton>
 *   <Tooltip text="Copy link" triggerAsChild>
 *     <IconButton variant="secondary" icon="Link" onClick={() => handleCopy(experimentLink)} />
 *   </Tooltip>
 *   <Button variant="secondary" href={experimentLink}>Open</Button>
 * </SplitButton>
 * ```
 */
export default function Tooltip({
  text,
  disabled,
  placement = 'top',
  delayShow = 300,
  triggerAsChild,
  id,
  tooltipProps,
  children
}: ITooltip) {
  const autoId = useId();
  const tooltipId = id ?? autoId;
  let trigger: ReactNode = <div data-tooltip-id={tooltipId}>{children}</div>;
  if (triggerAsChild) {
    const firstTriggerChild = Children.toArray(children).find((child): child is ReactElement => isValidElement(child));
    trigger = firstTriggerChild ? cloneElement(firstTriggerChild, {
      'data-tooltip-id': tooltipId
    }) : null;
  }
  return <>
      {trigger}
      {!disabled && createPortal(<TooltipBase id={tooltipId} place={placement} delayShow={delayShow} {...tooltipProps}>
            {text}
          </TooltipBase>, document.body)}
    </>;
}