/**
 * Color literals palette.
 *
 * Please reference semantic colors (theme/colors.ts) instead of this object in your code if you don't intend to *actually* use a value from the palette directly.
 *
 * Figma definition: {@link https://www.figma.com/design/q5V3oq0GCqLdnK6SjG2Ghl/%F0%9F%A7%B1-%5BOFFICIAL%5D-Foundations?node-id=2952-9992&m=dev | Foundations file}.
 */
export const palette = {
  white: '#ffffff',
  coffee: {
    '100': '#d5d1d0',
    '300': '#968c89',
    '500': '#4B2E26',
    base: '#2c1912',
  },
  neutral: {
    '100': '#fcfaf7',
    '200': '#f2f0ed',
    '300': '#eae6e1',
    '500': '#c7c2ba',
    '600': '#9d978f',
    '800': '#605b53',
    '1000': '#262422',
    base: '#dddad5',
  },
  peppo: {
    '100': '#f3efff',
    '300': '#e6e0ff',
    '500': '#ac99ff',
    '600': '#6c55d4',
    '800': '#5c37a1',
    '1000': '#271b33',
    base: '#8266ff',
  },
  lime: {
    '100': '#f5ffd9',
    '300': '#e6ffa0',
    '500': '#d5ff60',
    '600': '#abd535',
    '800': '#678020',
    '1000': '#29330d',
    base: '#CDFF40',
  },

  taro: {
    '100': '#f3e7ff',
    '300': '#e1c2ff',
    '500': '#cc99ff',
    '600': '#a26fd5',
    '800': '#614380',
    '1000': '#271b33',
    base: '#c285ff',
  },
  raspberry: {
    '100': '#fbd5e3',
    '300': '#f696b9',
    '500': '#ef508a',
    '600': '#c5265f',
    '800': '#761739',
    '1000': '#2f0917',
    base: '#ec2d72',
  },
  tangerine: {
    '100': '#ffdfcd',
    '300': '#ffaf83',
    '500': '#ff7a30',
    '600': '#d54f06',
    '800': '#803004',
    '1000': '#331301',
    base: '#ff5f07',
  },
  mint: {
    '100': '#d7f8ed',
    '300': '#9aeed2',
    '500': '#57e2b4',
    '600': '#2cb78a',
    '800': '#1b6e53',
    '1000': '#0b2c21',
    base: '#35dca5',
  },
  blueberry: {
    '100': '#ccf5f7',
    '300': '#80e6ec',
    '500': '#2ad5df',
    '600': '#00aab5',
    '800': '#00666d',
    '1000': '#00292b',
    base: '#00CCD9',
  },
} as const;
