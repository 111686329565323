export function domSafeElementId(id: string): string {
  return id.replace(/\W+/g, '_').toLocaleLowerCase();
}

export function scrollToElement(htmlId: string, smooth = false) {
  const element = document.getElementById(htmlId);
  if (element) {
    // need to wait for the card to be rendered when adding a new card
    setTimeout(() => {
      element.scrollIntoView(smooth ? { behavior: 'smooth', block: 'center' } : true);
    }, 100);
  }
}
