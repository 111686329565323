import { useReducer } from 'react';

import { createContext } from 'use-context-selector';

/**
 * The intention of creation of this global context is to be able to update parts of frontend which are not one the same component tree.
 * Please use this context only for flags changing of which will indicate refetch of data in one of the components (check ExperimentProgress.tsx for example)
 */
type State = {
  refreshProgressBarExperimentIds: number[];
  refreshCompanySettings: boolean;
  refreshAudiences: boolean;
  refreshTags: boolean;
  loadedProgressExperimentIds: string[];
  createdExploreId: string | undefined;
};

const initialState: State = {
  refreshProgressBarExperimentIds: [],
  refreshCompanySettings: false,
  refreshAudiences: false,
  refreshTags: false,
  loadedProgressExperimentIds: [],
  createdExploreId: undefined,
};

export enum ActionTypeEnum {
  'SET_REFRESH_PROGRESS_BAR_EXPERIMENT_IDS' = 'setRefreshProgressBarExperimentIds',
  'SET_REFRESH_COMPANY_SETTINGS' = 'setRefreshCompanySettings',
  'SET_REFRESH_AUDIENCES' = 'setRefreshAudiences',
  'SET_REFRESH_TAGS' = 'setRefreshTags',
  'ADD_LOADED_PROGRESS_EXPERIMENT_ID' = 'setLoadedProgressExperimentId',
  'SET_CREATED_EXPLORE_ID' = 'setCreatedExploreId',
}

type ActionTypes =
  | ActionTypeEnum.SET_REFRESH_PROGRESS_BAR_EXPERIMENT_IDS
  | ActionTypeEnum.SET_REFRESH_COMPANY_SETTINGS
  | ActionTypeEnum.ADD_LOADED_PROGRESS_EXPERIMENT_ID
  | ActionTypeEnum.SET_CREATED_EXPLORE_ID
  | ActionTypeEnum.SET_REFRESH_AUDIENCES
  | ActionTypeEnum.SET_REFRESH_TAGS;

type Action = {
  type: ActionTypes;
  experimentIds?: number[];
  refreshCompanySettings?: boolean;
  refreshAudiences?: boolean;
  refreshTags?: boolean;
  experimentId?: string;
  createdExploreId?: string;
};

type Dispatch = (action: Action) => void;

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypeEnum.SET_REFRESH_PROGRESS_BAR_EXPERIMENT_IDS:
      return {
        ...state,
        refreshProgressBarExperimentIds: action.experimentIds,
      };
    case ActionTypeEnum.SET_REFRESH_AUDIENCES:
      return {
        ...state,
        refreshAudiences: action.refreshAudiences,
      };
    case ActionTypeEnum.SET_REFRESH_TAGS:
      return {
        ...state,
        refreshTags: action.refreshTags,
      };
    case ActionTypeEnum.SET_REFRESH_COMPANY_SETTINGS:
      return {
        ...state,
        refreshCompanySettings: action.refreshCompanySettings,
      };
    case ActionTypeEnum.ADD_LOADED_PROGRESS_EXPERIMENT_ID:
      return {
        ...state,
        loadedProgressExperimentIds: [
          ...new Set([...state.loadedProgressExperimentIds, action.experimentId]),
        ],
      };
    case ActionTypeEnum.SET_CREATED_EXPLORE_ID:
      return {
        ...state,
        createdExploreId: action.createdExploreId,
      };
    default:
      throw new Error('unknown action type');
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Complex type failure here
export const useValue = () => useReducer(reducer, initialState);

export const GlobalContext = createContext<[State, Dispatch]>([initialState, () => null]);
