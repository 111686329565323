import { COLOR_GREY_100, COLOR_GREY_300 } from 'constants/colors';

import styled from '@emotion/styled';

import { colors } from 'theme/colors';

export const SearchBarDiv = styled.div(() => ({
  width: 330,
  height: 34,
  display: 'flex',
  background: COLOR_GREY_100,
  border: `1px solid ${COLOR_GREY_300}`,
  borderRadius: 8,
  padding: '0 10px 0 2px',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
}));

export const SearchBarInputDiv = styled.div(() => ({
  flexGrow: 1,
}));

export const SearchBarInputPlusSpan = styled.div(() => ({
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '16px',
  color: colors.text.secondary,
  margin: '0 3px',
}));

export const SearchBarOverlay = styled.div(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}));
