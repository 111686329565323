import { CHART_COLORS, COLOR_WHITE } from 'constants/colors';

import styled from '@emotion/styled';

export type ProfileImageSize = 'default' | 'small' | 'large' | 'medium';
export const ProfilePictureDiv = styled.div<{ size: ProfileImageSize; zIndex?: number }>(
  ({ size, zIndex }) => {
    let width = 32;
    let height = 32;

    switch (size) {
      case 'large':
        width = 160;
        height = 160;
        break;
      case 'medium':
        width = 64;
        height = 64;
        break;
      case 'small':
        width = 24;
        height = 24;
        break;
      default:
        width = 32;
        height = 32;
        break;
    }

    return {
      width,
      height,
      flex: `0 0 ${width}px`,
      borderRadius: '100%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex,
    };
  },
);

export const ProfilePictureImg = styled.img<{ size: ProfileImageSize; halfOpacity: boolean }>(
  ({ size, halfOpacity }) => {
    let width = 32;
    let height = 32;

    switch (size) {
      case 'large':
        width = 160;
        height = 160;
        break;
      case 'medium':
        width = 64;
        height = 64;
        break;
      case 'small':
        width = 24;
        height = 24;
        break;
      default:
        width = 32;
        height = 32;
        break;
    }

    return {
      width,
      height,
      opacity: halfOpacity ? 0.5 : 1,
    };
  },
);

const getProfileColor = (firstLetter?: string) => {
  if (!firstLetter) {
    return CHART_COLORS[0];
  }

  const diff = firstLetter.toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0);
  return CHART_COLORS[diff % CHART_COLORS.length];
};

export const ProfilePictureDefault = styled.div<{
  size: ProfileImageSize;
  firstLetter?: string;
  halfOpacity: boolean;
}>(({ size, firstLetter, halfOpacity }) => {
  let width = 32;
  let height = 32;
  let fontSize = 15;

  switch (size) {
    case 'large':
      width = 160;
      height = 160;
      fontSize = 72;
      break;
    case 'small':
      width = 24;
      height = 24;
      fontSize = 12;
      break;
    default:
      width = 32;
      height = 32;
      fontSize = 15;
      break;
  }
  return {
    background: getProfileColor(firstLetter),
    color: COLOR_WHITE,
    width: `${width}px`,
    height: `${height}px`,
    textAlign: 'center',
    lineHeight: `${height}px`,
    fontSize: fontSize,
    opacity: halfOpacity ? 0.5 : 1,
  };
});
