import styled from '@emotion/styled';
import { Property } from 'csstype';
export interface IBox {
  width?: Property.Width | number;
  height?: Property.Height | number;
  maxHeight?: Property.MaxHeight | number;
  maxWidth?: Property.MaxWidth | number;
  minHeight?: Property.MinHeight | number;
  minWidth?: Property.MinWidth | number;
  padding?: Property.Padding | number;
  margin?: Property.Margin | number;
  position?: Property.Position;
  display?: Property.Display;
  verticalAlign?: Property.VerticalAlign;
  overflow?: Property.Overflow;
  opacity?: Property.Opacity;
}
export const Box = styled.div<IBox>(({
  width,
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  padding,
  margin,
  position,
  display,
  verticalAlign,
  overflow,
  opacity
}) => ({
  width,
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  padding,
  margin,
  position,
  display,
  verticalAlign,
  overflow,
  opacity
}));