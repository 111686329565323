export const SPACE_XS = 2;
/** @deprecated use spacing.xs from 'theme' instead */
export const SPACE_S = 4;
/** @deprecated use spacing.sm from 'theme' instead */
export const SPACE_M = 8;
/** @deprecated use spacing.lg from 'theme' instead */
export const SPACE_L = 16;
/** @deprecated use spacing.xxl from 'theme' instead */
export const SPACE_XL = 32;
export const SPACE_XXL = 48;

export const FORM_ELEMENT_HEIGHT = 32;
export const FORM_ELEMENT_LARGE_HEIGHT = 48;

export const MAGIC_WIDTH = 1064;

export const HEADER_HEIGHT_WITHOUT_SUBHEADER = 50;

export const HEADER_HEIGHT = '64px';
